import { NavLink, useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import { Candidate, Profile, Seniority, Tech } from '../../types'
import SearchCandidates from '../../components/search/searchCandidates'
import CandidatesFilter from '../../components/filters/candidatesFilter'
import { useTalentApi } from '../../store/mainContext'
import { ENV_VARS } from '../../env'
import RemoteValue from '../../components/remoteValues/remoteValue'
import RemoteStars from '../../components/remoteValues/RemoteStars'
import PageHeader from '../../components/pageHeader/pageHeader'
import PagedTable from '../../components/dataTable/pagedTable'
import Badges from '../../components/remoteValues/badges'
import Period from '../../components/period'

const PageContent = styled.div`
  margin: 0px 50px 50px 50px;
`

const TechLabel = styled.div`
  border: solid 1px var(--border-grey);
  background-color: var(--bg-grey);

  padding: 3px 7px;
  margin: 3px;
  float: left;
  border-radius: 4px;
  font-size: 12px;
`

const Candidates = () => {
  document.title = 'Candidates'
  const history = useHistory()

  const api = useTalentApi()

  const [filter, setFilter] = useState({})

  const handleAddCandidate = async () => {
    const { data } = await api.post('candidates', {
      name: 'New Candidate',
    })

    history.push(`/candidates/${data._id}`)
  }

  return (
    <React.Fragment>
      <PageHeader
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Candidates', link: '/candidates' },
        ]}
        title="Candidates"
        sub="One screen to find them all."
        search={
          <div className="flex items-baseline gap-4">
            <NavLink
              to="/candidates/search"
              className="text-sm !text-slate-500 underline decoration-slate-300 hover:!text-primary"
            >
              Advanced Search
            </NavLink>
            <SearchCandidates />
          </div>
        }
        actions={
          <Button basic onClick={handleAddCandidate}>
            <Icon name="add" /> Add Candidate
          </Button>
        }
      ></PageHeader>

      <PageContent>
        <CandidatesFilter onFilterChange={setFilter} />

        <PagedTable
          collection="candidates"
          filter={filter}
          onRowClick={(item: Candidate) =>
            (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
              ? window.open
              : history.push)(`/candidates/${item._id}`)
          }
          defaultSort="lastEvent"
          columns={[
            {
              title: 'Name',
              width: 200,
              field: 'name',
            },
            {
              title: 'Level',
              width: 100,
              align: 'center',
              field: 'seniority',
              render: (seniority: Seniority['_id']) => (
                <RemoteValue
                  collection="seniority"
                  id={seniority}
                  key={seniority}
                />
              ),
            },
            {
              title: 'Profiles',
              width: 150,
              align: 'center',
              field: 'profiles',
              render: (profiles: Profile['_id'][]) =>
                (profiles || []).map((id) => (
                  <TechLabel key={id}>
                    <RemoteValue collection="profiles" id={id} key={id} />
                  </TechLabel>
                )),
            },
            {
              title: 'Main Techs',
              width: 200,
              align: 'center',
              field: 'mainTechs',
              render: (mainTechs: Tech['_id'][]) =>
                (mainTechs || []).map((id) => (
                  <TechLabel key={id}>
                    <RemoteValue collection="techs" id={id} key={id} />
                  </TechLabel>
                )),
            },
            {
              title: 'English',
              width: 120,
              align: 'center',
              field: 'englishLevel',
              render: (englishLevel: string) => (
                <RemoteStars
                  collection="englishLevels"
                  maxRating={5}
                  value={englishLevel}
                  key={englishLevel}
                />
              ),
            },
            {
              title: 'Fee',
              width: 90,
              align: 'center',
              field: 'salary',
              render: (salary: number) => salary && `$ ${salary}`,
            },
            {
              title: 'Location',
              width: 130,
              align: 'center',
              field: 'location',
            },
            {
              title: 'Y of E',
              width: 90,
              align: 'center',
              field: 'yearsInTheIndustry',
            },
            {
              title: 'Badges',
              width: 120,
              align: 'center',
              field: 'badges',
              render: (badges) => <Badges value={badges} />,
            },
            {
              title: 'Last Touch',
              width: 120,
              align: 'center',
              field: 'lastEvent',
              render: (lastEvent) => <Period date={lastEvent} />,
            },
          ]}
        />
      </PageContent>
    </React.Fragment>
  )
}

export default Candidates
