import {
  SemanticICONS,
  DropdownItem,
  Dropdown,
  Popup,
  Icon,
} from 'semantic-ui-react'
import React, { ReactNode, useEffect, useState } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { endOfToday } from 'date-fns'
import styled from 'styled-components'
import useSWR from 'swr'
import { z } from 'zod'

import './sidebar.css'

import { useUser, useSessionActions } from '../../pages/session/hooks'
import { validateResponse } from '../../utils'
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut'
import QuickSearchDialog from '../quick-search/QuickSearchDialog'
import { useTalentApi } from '../../store/mainContext'
import { funnelSchema } from '../../types'
import featureToggles from '../../featureToggles'
import RequirePowers from '../requirePowers'
import QuickAddMenu from './quickAddMenu'
import { socket } from '../../socket'
import usePowers from '../../hooks/usePowers'
import POWERS from '../../powers'

const ItemContainer = styled.div`
  position: relative;
`

const Notification = styled.div`
  position: absolute;
  top: 2px;
  right: 9px;
  background-color: var(--red);
  color: #fff;
  text-align: center;
  min-width: 22px;
  min-height: 22px;
  max-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 7px;
  padding: 2px;
  font-size: 12px;
  font-weight: bold;
  pointer-events: none;
`

const Divider = styled.hr`
  border: none;
  border-bottom: dotted 1px var(--border-grey);
`

interface MenuItemProps {
  notifications?: number
  disabled?: boolean
  linkTo?: NavLinkProps['to']
  exact?: NavLinkProps['exact']
  icon: SemanticICONS
  text: ReactNode
  onClick?(): any
}

const MenuItem = (props: MenuItemProps) => {
  return (
    <ItemContainer>
      <Popup
        trigger={
          props.disabled ? (
            <div className="sideMenuItem disabled">
              <Icon name={props.icon} />
            </div>
          ) : props.linkTo ? (
            <NavLink
              activeClassName="active"
              className="sideMenuItem"
              exact={props.exact}
              to={props.linkTo}
            >
              <Icon name={props.icon} />
            </NavLink>
          ) : (
            <button
              className="sideMenuItem w-full"
              onClick={props.onClick}
              type="button"
            >
              <Icon name={props.icon} />
            </button>
          )
        }
        content={props.text}
        position="right center"
        offset={[0, -15]}
      />
      {typeof props.notifications === 'number' && props.notifications > 0 && (
        <Notification>{props.notifications}</Notification>
      )}
    </ItemContainer>
  )
}

interface MenuWithSubMenuItemProps {
  subMenu: { text: string; linkTo: NavLinkProps['to']; show?: boolean }[]
  linkTo: NavLinkProps['to']
  icon: SemanticICONS
  text: ReactNode
}

const MenuWithSubMenuItem = (props: MenuWithSubMenuItemProps) => {
  const [showSubMenu, setShowSubMenu] = useState(false)

  return (
    <React.Fragment>
      <Dropdown
        onMouseEnter={() => setShowSubMenu(true)}
        onMouseLeave={() => setShowSubMenu(false)}
        open={showSubMenu}
        icon={null}
        pointing="left"
        fluid
        style={{ left: -25, top: 6 }}
      >
        <Dropdown.Menu style={{ zIndex: 9999 }}>
          {props.subMenu.map(
            (x) =>
              x.show !== false && (
                <DropdownItem
                  onClick={() => setShowSubMenu(false)}
                  style={{ zIndex: 9999 }}
                  key={x.text}
                  as={NavLink}
                  to={x.linkTo}
                >
                  {x.text}
                </DropdownItem>
              ),
          )}
        </Dropdown.Menu>
      </Dropdown>

      <NavLink
        activeClassName="active"
        className="sideMenuItem"
        onMouseEnter={() => setShowSubMenu(true)}
        onMouseLeave={() => setShowSubMenu(false)}
        to={props.linkTo}
      >
        <i className={`icon ${props.icon}`}></i>
      </NavLink>
    </React.Fragment>
  )
}

type Dialog = { type: 'search' }

const SideBar = () => {
  const { logOut } = useSessionActions()
  const user = useUser()
  const api = useTalentApi()

  const [currentDialog, setCurrentDialog] = useState<Dialog | null>(null)

  useKeyboardShortcut(() => setCurrentDialog({ type: 'search' }), {
    code: 'KeyK',
    metaKey: true,
  })

  // get tasks 1) assigned to user, 2) due today or overdue and 3) not done
  const params = {
    assignedToId: user._id,
    dueDate: { $lte: endOfToday().getTime() },
    isDone: { $ne: true },
  }

  // initially get tasks count
  const { data: tasksCount, mutate } = useSWR(['tasks-count', params], () =>
    api
      .get(`tasks/pro`, { params })
      .then((res) => parseInt(res.headers['astor-total'], 10)),
  )

  const { data: funnels } = useSWR('funnels-by-collection', () => {
    return api
      .get('funnels')
      .then(validateResponse(z.array(funnelSchema)))
      .then((fs) => fs.sort((a, b) => a.order - b.order))
      .then((fs) => ({
        candidates: fs.filter((f) => f.collection === 'candidates'),
        contacts: fs.filter((f) => f.collection === 'contacts'),
      }))
  })

  // subscribe to tasks events and refresh the tasks count
  useEffect(() => {
    socket.on('tasks-updated', () => mutate())
    return () => {
      socket.off('tasks-updated', () => mutate())
    }
  }, [mutate])

  const powers = usePowers()

  return (
    <div id="sidebar" className="shadow-md">
      <div>
        <div className="logo p-3">
          <Dropdown
            trigger={
              <img
                alt={user.name}
                style={{
                  background: '#FAFAFA',
                  borderRadius: 17,
                  width: 35,
                  height: 35,
                  margin: '0 auto',
                }}
                src={user.image}
              />
            }
            icon={null}
            pointing="left"
            fluid
            style={{ textAlign: 'center' }}
          >
            <Dropdown.Menu style={{ zIndex: 9999 }}>
              <DropdownItem>
                <span>
                  Signed in as <strong>{user.name}</strong>
                </span>
              </DropdownItem>
              <Divider></Divider>
              <DropdownItem>Your Profile</DropdownItem>
              <DropdownItem>Settings</DropdownItem>
              <Divider></Divider>
              <DropdownItem onClick={logOut}>
                <Icon name="sign out alternate" />
                &nbsp; <strong>Sign out</strong>
              </DropdownItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <MenuItem exact icon="home" text="Dashboard" linkTo="/" disabled />

        <Divider />

        <MenuItem
          icon="bell outline"
          text="Notifications"
          linkTo="/news"
          disabled
        />

        <MenuItem
          icon="check circle outline"
          text="Tasks"
          linkTo="/tasks"
          notifications={tasksCount}
        />

        {featureToggles.emails && (
          <RequirePowers powers={['viewEmails']}>
            <MenuItem icon="inbox" text="Inbox" linkTo="/emails/inbox" />
          </RequirePowers>
        )}

        <Divider />

        <MenuWithSubMenuItem
          icon="map outline"
          linkTo={`/candidates/funnels/${(funnels?.candidates[0] || {}).name}`}
          text="Candidates Funnels"
          subMenu={(funnels?.candidates || []).map((f) => ({
            text: f.title,
            linkTo: `/candidates/funnels/${f.name}`,
          }))}
        />

        <MenuWithSubMenuItem
          icon="user circle outline"
          linkTo="/candidates"
          text="Candidates"
          subMenu={[
            { text: 'Candidates', linkTo: '/candidates' },
            { text: 'Lists', linkTo: '/candidates/lists' },
            { text: 'Advanced Search', linkTo: '/candidates/search' },
          ]}
        />

        <MenuItem
          icon="newspaper outline"
          text="Positions"
          linkTo="/positions"
        />

        <RequirePowers powers={[POWERS.contactsAndCompanies]}>
          <Divider />
          <MenuWithSubMenuItem
            icon="map"
            linkTo={`/contacts/funnels/${
              ((funnels?.contacts || [])[0] || {}).name
            }`}
            text="Contacts Funnels"
            subMenu={(funnels?.contacts || []).map((f) => ({
              text: f.title,
              linkTo: `/contacts/funnels/${f.name}`,
            }))}
          />

          <MenuItem
            icon="user circle"
            text="Contacts"
            linkTo="/contacts"
            exact
          />

          <MenuItem icon="building" text="Companies" linkTo="/companies" />
        </RequirePowers>

        <RequirePowers powers={[POWERS.contracts]}>
          <Divider />
          <MenuItem
            icon="address card outline"
            text="Contracts"
            linkTo="/contracts"
          />
        </RequirePowers>

        {powers.hasAny(['payment-orders', 'invoicing', 'payrolls']) && (
          <>
            <Divider />
            <MenuWithSubMenuItem
              icon="wordpress forms"
              linkTo="/billing-dashboard"
              text="Billing"
              subMenu={[
                {
                  text: 'Dashboard',
                  linkTo: '/billing-dashboard',
                  show: powers.hasAll(['invoicing']),
                },
                {
                  text: 'Billing',
                  linkTo: '/billing',
                  show: powers.hasAll(['invoicing']),
                },
                {
                  text: 'Invoices & Payments',
                  linkTo: '/invoices',
                  show: powers.hasAll(['invoicing']),
                },
                {
                  text: 'Payroll',
                  linkTo: '/payrolls',
                  show: powers.hasAll(['payrolls']),
                },
                {
                  text: 'Payment Orders',
                  linkTo: '/payment-orders',
                  show: powers.hasAll(['payment-orders']),
                },
              ]}
            />
          </>
        )}

        {featureToggles.recipes && (
          <RequirePowers powers={[POWERS.manageRecipes]}>
            <Divider />
            <MenuItem icon="lab" text="Recipes" linkTo="/recipes" />
          </RequirePowers>
        )}

        <RequirePowers powers={[POWERS.settings]}>
          <Divider />
          <MenuItem icon="settings" text="Settings" linkTo="/settings" />
        </RequirePowers>
      </div>
      <div>
        <MenuItem
          icon="search"
          onClick={() => setCurrentDialog({ type: 'search' })}
          text={
            <span>
              Search{' '}
              <span className="pl-3 text-sm font-semibold text-slate-400">
                ⌘K
              </span>
            </span>
          }
        />

        <QuickAddMenu />
      </div>

      <QuickSearchDialog
        onClose={() => setCurrentDialog(null)}
        open={currentDialog?.type === 'search'}
      />
    </div>
  )
}

export default SideBar
