import * as React from 'react'

import { Candidate, Position, Company, Contact } from '../../../types'

interface Props {
  candidate: Partial<
    Candidate & { position: Position; company: Company; hiringManager: Contact }
  >
}

export default function CandidateCard(props: Props) {
  const { candidate } = props

  return candidate.position ? (
    <div>
      <div className="text-base leading-tight text-slate-500">
        {candidate.position.number} - {candidate.position.title}
      </div>
      {candidate.hiringManager && (
        <div className="py-1 text-base leading-snug text-slate-500">
          {candidate.hiringManager.name}
        </div>
      )}
      {candidate.company && (
        <div className="mt-1 text-base font-semibold leading-tight text-slate-500">
          {candidate.company.name}
        </div>
      )}
    </div>
  ) : (
    <div className="text-base leading-tight text-slate-500">
      No position related yet.
    </div>
  )
}
