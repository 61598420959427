import { Checkbox, Dropdown, Icon, Input } from 'semantic-ui-react'
import useSWR, { KeyedMutator } from 'swr'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import FuzzySearch from 'fuzzy-search'
import * as React from 'react'
import { z } from 'zod'

import {
  entityListSchema,
  positionSchema,
  companySchema,
  contactSchema,
  Candidate,
} from '../../types'
import { classNames, validateResponse } from '../../utils'
import { useToasts } from '../toasts/ToastsProvider'
import ConfirmModal from '../modals/confirmModal'
import { ENV_VARS } from '../../env'
import { useApi } from '../../store/mainContext'

type Dialog =
  | { type: 'assign-position'; defaultPositionId?: string }
  | { type: 'unlink-position' }
  | { type: 'manage-lists' }

interface Props {
  candidate: Candidate
  mutate: KeyedMutator<Candidate[]>
}

export default function CardActionsMenu(props: Props) {
  const { candidate, mutate } = props

  const { addToast } = useToasts()
  const history = useHistory()
  const api = useApi()

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)
  const [positionSearch, setPositionSearch] = React.useState('')
  const [showClosed, setShowClosed] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)

  const lists = useSWR(['lists', 'candidates'], () =>
    api
      .get('/lists', { params: { collection: 'candidates' } })
      .then(validateResponse(z.array(entityListSchema))),
  )

  const { data } = useSWR('positions', async () => {
    const positions = await api
      .get('/positions')
      .then(validateResponse(z.array(positionSchema)))

    const [companies, contacts] = await Promise.all([
      api
        .get('companies/in', {
          params: { _id: positions.map((x) => x.companyId) },
        })
        .then(validateResponse(z.array(companySchema))),
      api
        .get('contacts/in', {
          params: { _id: positions.map((x) => x.hiringManagerId) },
        })
        .then(validateResponse(z.array(contactSchema))),
    ])

    return positions.map((p) => {
      const hiringManager = contacts.find((x) => x._id === p.hiringManagerId)
      const company = companies.find((c) => c._id === p.companyId)
      return {
        value: p._id,
        key: p._id,
        text: `${p.number} - ${company?.name || 'No Company'} - ${p.title} (${hiringManager?.name || 'No HM'})${p.status === 'closed' ? ' (Closed)' : ''}`,
        status: p.status,
      }
    })
  })

  const filteredPositions = new FuzzySearch(data || [], ['text'])
    .search(positionSearch || '')
    .filter((p) => (showClosed ? true : p.status !== 'closed'))

  React.useEffect(() => {
    if (!isOpen) return
    setPositionSearch('')
    setCurrentDialog(null)
    setShowClosed(false)
  }, [isOpen])

  const [isPending, setIsPending] = React.useState(false)

  const candidateLists = candidate.lists || []

  const handleListsUpdated = async (listId: string, checked?: boolean) => {
    const newLists = candidateLists.includes(listId)
      ? candidateLists.filter((l) => l !== listId)
      : [...candidateLists, listId]
    return mutate(
      () =>
        api
          .patch('/candidates/' + candidate._id, { lists: newLists })
          .catch((err: AxiosError) =>
            addToast(err.response?.data.message || err.message, {
              variant: 'danger',
            }),
          ),
      {
        populateCache: false,
        optimisticData: (currentData, displayedData) => {
          console.log({ currentData, displayedData })
          return (displayedData || []).map((c) =>
            c._id === candidate._id ? { ...c, lists: newLists } : c,
          )
        },
      },
    )
  }

  const handlePositionSelected = async (positionId: string) => {
    setIsPending(true)
    return api
      .patch('candidates/' + candidate._id, { positionId })
      .then(() => mutate())
      .then(() => setIsOpen(false))
      .catch((err: AxiosError) => {
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        })
      })
      .finally(() => setIsPending(false))
  }

  const handleUnlinkPosition = async () => {
    if (!candidate.positionId) return
    return api
      .delete(`/candidates/${candidate._id}/positionId`)
      .then(() => mutate())
      .then(() => setIsOpen(false))
      .then(() => setCurrentDialog(null))
      .catch((err: AxiosError) => {
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        })
      })
  }

  const position = (data || []).find((p) => p.value === candidate.positionId)

  return (
    <>
      <Dropdown
        closeOnBlur={!isPending}
        icon="ellipsis horizontal"
        open={isOpen}
        onClick={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
        direction="left"
      >
        <Dropdown.Menu onClick={(e: any) => e.stopPropagation()}>
          {currentDialog === null && (
            <>
              <Dropdown.Item
                icon="user circle outline"
                text="View Profile"
                onClick={() =>
                  (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                    ? window.open
                    : history.push)(`/candidates/${candidate._id}`)
                }
              />
              <Dropdown.Divider />

              <Dropdown.Item
                text="Manage Lists"
                icon="list"
                onClick={(e: any) => {
                  e.stopPropagation()
                  setCurrentDialog({ type: 'manage-lists' })
                }}
              />

              <Dropdown.Divider />
              {candidate.positionId && (
                <Dropdown.Header>Position</Dropdown.Header>
              )}
              <Dropdown.Item
                onClick={(e: any) => {
                  e.stopPropagation()
                  setCurrentDialog({
                    type: 'assign-position',
                    defaultPositionId: candidate.positionId || undefined,
                  })
                }}
                icon={candidate.positionId ? 'refresh' : 'newspaper outline'}
                text={
                  candidate.positionId ? 'Switch...' : 'Assign a Position...'
                }
              />
              {candidate.positionId && (
                <Dropdown.Item
                  text="Unlink"
                  icon="broken chain"
                  color="red"
                  onClick={() => setCurrentDialog({ type: 'unlink-position' })}
                />
              )}
              {candidate.positionId && (
                <Dropdown.Item
                  text="View Position"
                  icon="share square outline"
                  onClick={() =>
                    (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                      ? window.open
                      : history.push)(`/positions/${candidate.positionId}`)
                  }
                />
              )}
            </>
          )}

          {currentDialog?.type === 'manage-lists' && (
            <Dropdown.Header className="flex">
              <Icon
                disabled={isPending}
                name="chevron left"
                onClick={() => setCurrentDialog(null)}
                link
              />
              <span className="grow">Manage Lists</span>
            </Dropdown.Header>
          )}

          {currentDialog?.type === 'manage-lists' && (
            <Dropdown.Menu scrolling>
              {lists.data?.map((list) => {
                return (
                  <Dropdown.Item key={list._id}>
                    <label className="cursor-pointer">
                      <Checkbox
                        checked={candidateLists.includes(list._id)}
                        onChange={(_, p) => {
                          handleListsUpdated(list._id, p.checked)
                        }}
                      />
                      {list.name}
                    </label>
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          )}

          {currentDialog?.type === 'assign-position' && (
            <>
              <Dropdown.Header className="flex">
                <Icon
                  disabled={isPending}
                  name="chevron left"
                  onClick={() => setCurrentDialog(null)}
                  link
                />
                <span className="grow">
                  {currentDialog.defaultPositionId
                    ? 'Switch positions'
                    : 'Assign a Position'}
                </span>
                <span className="mr-2 font-normal normal-case text-slate-600">
                  Show closed
                </span>
                <Checkbox
                  onChange={(_, t) => setShowClosed(!!t.checked)}
                  disabled={isPending}
                  toggle
                  checked={showClosed}
                />
              </Dropdown.Header>
              <Input
                disabled={isPending}
                className="search"
                value={positionSearch}
                onChange={(_, p) => setPositionSearch(p.value)}
                icon={
                  positionSearch.length > 0
                    ? {
                        name: 'times',
                        link: true,
                        onClick: () => setPositionSearch(''),
                      }
                    : 'search'
                }
              />
              <Dropdown.Menu scrolling>
                {filteredPositions.map((pos) => {
                  return (
                    <Dropdown.Item
                      onClick={() => handlePositionSelected(pos.value)}
                      disabled={isPending}
                      key={pos.value}
                    >
                      <span
                        className={classNames(
                          pos.status === 'closed' && 'text-slate-400',
                        )}
                      >
                        {pos.text}
                      </span>
                    </Dropdown.Item>
                  )
                })}
                {filteredPositions.length === 0 && (
                  <Dropdown.Item disabled className="italic">
                    No positions
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>

      {currentDialog?.type === 'unlink-position' && (
        <ConfirmModal
          title="Unlink Position"
          question="You are about to unlink this position:"
          highlight={position?.text}
          onCancel={() => setCurrentDialog(null)}
          onConfirm={handleUnlinkPosition}
          show
        />
      )}
    </>
  )
}
