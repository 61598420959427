import { NavLink, Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import { Button, Confirm, Placeholder, Popup } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { Helmet } from 'react-helmet'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { candidateSchema, EntityList, entityListSchema } from '../../types'
import { classNames, validateResponse } from '../../utils'
import SearchResultCard from '../../components/candidates/SearchResultCard'

import { useToasts } from '../../components/toasts/ToastsProvider'

import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'

import CandidatePreview from '../../components/candidates/CandidatePreview'
import EditableValue from '../../components/recipes/ui/EditableValue'

type RouteParams = { listId?: string }

type Dialog =
  | { type: 'delete-list'; list: EntityList }
  | { type: 'edit-list'; list: EntityList }
  | { type: 'create-list' }

export default function CandidatesListsPage() {
  const { params } = useRouteMatch<RouteParams>()
  const { listId } = params

  const { addToast } = useToasts()
  const history = useHistory()
  const api = useApi()

  const [selectedCandidateId, setSelectedCandidateId] = React.useState<string>()
  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)

  const [isDeleting, setIsDeleting] = React.useState(false)

  const lists = useSWR(['lists', 'candidates'], () =>
    api
      .get('/lists', { params: { collection: 'candidates' } })
      .then(validateResponse(z.array(entityListSchema))),
  )

  const members = useSWR(listId ? ['lists', 'candidates', listId] : null, () =>
    api
      .get('/candidates/in', { params: { lists: listId } })
      .then(validateResponse(z.array(candidateSchema))),
  )

  const selectedCandidate = members.data?.find(
    (candidate) => candidate._id === selectedCandidateId,
  )
  const selectedList = lists.data?.find((list) => list._id === listId)

  React.useEffect(() => {
    if (selectedList) setSelectedCandidateId(undefined)
  }, [selectedList])

  React.useEffect(() => {
    if (members.data?.length && members.data[0]) {
      setSelectedCandidateId(members.data[0]._id)
    }
  }, [members.data])

  const handleUpdateList = async (values: Partial<EntityList>) => {
    await api.patch<EntityList>(`lists/${selectedList?._id}`, values)
    lists.mutate()
  }

  const handleAddList = async () => {
    const { data } = await api.post<EntityList>(`lists`, {
      name: 'New List',
      description: 'Just another list.',
      collection: 'candidates',
    })

    lists.mutate().then(() => {
      history.push(`/candidates/lists/${data._id}`)
    })
  }

  if (lists.isLoading) return <PageLoader />

  if (!lists.data) {
    addToast('Lists not found', { variant: 'danger' })
    return <Redirect to="/candidates" />
  }

  // If in root "/lists" URL, automatically navigate to first list (if one exists)
  const maybeFirstListId = lists.data[0]?._id
  if (!params.listId && maybeFirstListId) {
    return <Redirect to={`/candidates/lists/${maybeFirstListId}`} />
  }

  const handleDeleteList = async (id: string) => {
    setIsDeleting(true)
    return api
      .delete('/lists/' + id)
      .then(() => lists.mutate())
      .then(() => {
        if (maybeFirstListId) {
          history.push(`/candidates/lists/${maybeFirstListId}`)
        }
        setCurrentDialog(null)
      })
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsDeleting(false))
  }

  return (
    <>
      <Helmet>
        <title>Candidates Lists</title>
      </Helmet>

      <div className="flex h-screen justify-between ">
        <div className="w-[240px] shrink-0 self-stretch border-r border-dotted border-border-grey">
          <header className="sticky top-0 flex items-baseline justify-between gap-4 border-b bg-white px-3 py-3">
            <span className="text-sm font-semibold uppercase leading-[29px] text-slate-500">
              Lists{' '}
              <span className="ml-0.5 font-normal text-slate-400">
                ({lists.data.length.toString()})
              </span>
            </span>

            <Popup
              content="Create List"
              position="left center"
              trigger={
                <Button
                  onClick={handleAddList}
                  icon="plus"
                  basic
                  size="mini"
                  className="!mr-0"
                />
              }
            />
          </header>

          {lists.data.length > 0 ? (
            <ul>
              {lists.data.map((list) => (
                <li
                  key={list._id}
                  className="cursor-pointer border-b border-dotted border-border-grey"
                >
                  <NavLink
                    to={`/candidates/lists/${list._id}`}
                    className="block w-full bg-white px-3 py-4 text-left !text-black  hover:bg-slate-50"
                    activeClassName="!bg-primary/5"
                  >
                    <div className="font-semibold leading-snug">
                      {list.name || (
                        <span className="italic text-slate-400">No name</span>
                      )}
                    </div>
                    <div
                      className={classNames(
                        'text-sm',
                        list.description
                          ? 'text-slate-500'
                          : 'italic text-slate-400',
                      )}
                    >
                      {list.description || 'No description'}
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
          ) : (
            <div className="px-3 py-8 text-center text-slate-500">
              Add your first list!
            </div>
          )}
        </div>

        <div className="w-[450px] shrink-0  border-r border-dotted border-border-grey pt-5">
          {selectedList ? (
            <>
              <div className="flex items-start justify-between px-6">
                <div>
                  <EditableValue
                    type="input"
                    className="min-w-[300px] max-w-[400px] text-3xl"
                    value={selectedList.name}
                    onSave={(value) => handleUpdateList({ name: value })}
                  />
                  <EditableValue
                    type="input"
                    className="min-w-[300px] max-w-[400px] text-gray-500"
                    value={selectedList.description || 'Just a list.'}
                    onSave={(value) => handleUpdateList({ description: value })}
                  />
                </div>

                <div className="flex gap-2">
                  <Popup
                    content="Delete List"
                    position="top center"
                    trigger={
                      <Button
                        className="!mr-0"
                        onClick={() =>
                          setCurrentDialog({
                            type: 'delete-list',
                            list: selectedList,
                          })
                        }
                        type="button"
                        icon="trash alternate outline"
                        size="mini"
                        color="red"
                        basic
                      />
                    }
                  />
                </div>
              </div>

              {members.isLoading ? (
                <ul className="space-y-3">
                  <li className="rounded-md border px-5 py-6">
                    <Placeholder>
                      <Placeholder.Header>
                        <Placeholder.Line length="short" />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="full" />
                        <Placeholder.Line length="short" />
                        <Placeholder.Line length="medium" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </li>
                  <li className="rounded-md border px-5 py-6">
                    <Placeholder>
                      <Placeholder.Header>
                        <Placeholder.Line length="short" />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="medium" />
                        <Placeholder.Line length="short" />
                        <Placeholder.Line length="full" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </li>
                  <li className="rounded-md border px-5 py-6">
                    <Placeholder>
                      <Placeholder.Header>
                        <Placeholder.Line length="short" />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="medium" />
                        <Placeholder.Line length="short" />
                        <Placeholder.Line length="full" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </li>
                </ul>
              ) : (
                <div>
                  <div className="mb-2 px-4 text-right text-base text-slate-500">
                    {(members.data || []).length.toString()}{' '}
                    {(members.data || []).length === 1
                      ? 'candidate'
                      : 'candidates'}
                  </div>

                  {members.data && members.data.length > 0 ? (
                    <div>
                      {(members.data || []).map((candidate) => {
                        return (
                          <div
                            className={classNames(
                              'cursor-pointer  border-t border-dotted border-border-grey p-4',
                              selectedCandidateId === candidate._id &&
                                ' bg-primary/5',
                            )}
                            onClick={() => {
                              setSelectedCandidateId(candidate._id)
                            }}
                            key={candidate._id}
                          >
                            <SearchResultCard
                              candidate={candidate}
                              mutate={members.mutate}
                            />
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <div className="mt-20 text-center">
                      <p>No candidates yet.</p>
                      <NavLink to="/candidates/search">Add candidates!</NavLink>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="py-4 text-center text-slate-500">
              Select a list to view candidates assigned to it.
            </div>
          )}
        </div>

        {selectedCandidate ? (
          <CandidatePreview selectedCandidate={selectedCandidate} />
        ) : (
          <div className="mt-60 w-full text-center">
            Pick a candidate to see his/her profile.{' '}
          </div>
        )}
      </div>

      {currentDialog?.type === 'delete-list' && (
        <Confirm
          header="Hey... deleting the list?"
          content={
            <div className="-mt-1 mb-2 px-6 text-base">
              This action will also unlink all the list candidates (if any).
            </div>
          }
          closeOnDimmerClick={!isDeleting}
          closeOnEscape={!isDeleting}
          onConfirm={() => handleDeleteList(currentDialog.list._id)}
          onCancel={() => setCurrentDialog(null)}
          onClose={() => setCurrentDialog(null)}
          cancelButton={<Button basic disabled={isDeleting} content="Cancel" />}
          confirmButton={
            <Button
              disabled={isDeleting}
              loading={isDeleting}
              content="Sure, delete"
              primary={false}
              color="red"
            />
          }
          size="tiny"
          open
        />
      )}
    </>
  )
}
