import { NavLink, useHistory } from 'react-router-dom'
import { Button, Select } from 'semantic-ui-react'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { Funnel, funnelSchema } from '../../types'
import { validateResponse } from '../../utils'
import SearchCandidates from '../../components/search/searchCandidates'
import SearchContacts from '../search/searchContacts'
import RequirePowers from '../../components/requirePowers'
import { useApi } from '../../store/mainContext'
import POWERS from '../../powers'

interface Props {
  funnel: Funnel
}

export default function FunnelHeader(props: Props) {
  const { funnel } = props

  const history = useHistory()
  const api = useApi()

  const funnels = useSWR(['funnels', funnel.collection], () =>
    api
      .get('funnels', { params: { collection: funnel.collection } })
      .then(validateResponse(z.array(funnelSchema)))
      .then((f) => f.sort((a, b) => a.order - b.order)),
  )

  const handleAddEntityClick = (name: string) => {
    return api
      .post(funnel.collection, { name })
      .then(validateResponse(z.object({ _id: z.string() })))
      .then(({ _id }) => history.push(`/${funnel.collection}/${_id}`))
  }

  return (
    <div className="flex items-baseline gap-2 p-4">
      <div className="flex items-baseline">
        <label className="pr-2 font-semibold">View:</label>
        <Select
          placeholder="Pick one..."
          loading={funnels.isLoading}
          disabled={funnels.isLoading}
          value={funnel.name}
          options={(funnels.data || [])?.map((f) => ({
            text: f.title,
            value: f.name,
            onClick: () => history.push(`/${f.collection}/funnels/${f.name}`),
          }))}
        />
      </div>
      <RequirePowers powers={[POWERS.master]}>
        <Button
          to={`/${funnel.collection}/funnels/${funnel.name}/edit`}
          as={NavLink}
          className="!mr-0"
          icon="pencil"
          basic
        />
      </RequirePowers>
      <Button
        className="!mr-0"
        basic
        icon="refresh"
        onClick={() => {
          Object.keys(window.localStorage)
            .filter((key) => key.startsWith('cache-'))
            .forEach((key) => window.localStorage.removeItem(key))
          document.location.reload()
        }}
      />
      <div className="grow" />

      {funnel.collection === 'candidates' && <SearchCandidates />}
      {funnel.collection === 'contacts' && <SearchContacts />}

      <Button
        content={
          funnel.collection === 'candidates' ? 'Add Candidate' : 'Add Contact'
        }
        onClick={() => {
          handleAddEntityClick(
            funnel.collection === 'candidates'
              ? 'New Candidate'
              : 'New Contact',
          )
        }}
        className="!mr-0"
        icon="plus"
        basic
      />
    </div>
  )
}
