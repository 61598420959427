import { Rating, RatingProps } from 'semantic-ui-react'
import * as React from 'react'
import useSWR from 'swr'

import { Collection } from '../../types'
import { useApi } from '../../store/mainContext'

interface Props extends RatingProps {
  collection: Collection
  value?: string
}

export default function RemoteStars(props: Props) {
  const { collection, value, ...other } = props

  const api = useApi()

  const rating = useSWR(
    value ? ['remote-stars', collection, value] : null,
    () => api.get(`${collection}/${value}`).then(({ data }) => data.value),
  )

  if (!value) {
    return <span className="text-sm text-slate-500">Not set yet</span>
  }

  return (
    <Rating
      rating={rating.data || 0}
      icon="star"
      disabled
      value={value}
      {...other}
    />
  )
}
