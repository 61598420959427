import {
  OnDragEndResponder,
  DragDropContext,
  Draggable,
  Droppable,
} from 'react-beautiful-dnd'
import { Button, Confirm, Modal } from 'semantic-ui-react'
import { useSWRConfig } from 'swr'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import * as React from 'react'

import {
  RecipeSchedule,
  Candidate,
  Contact,
  Recipe,
  Funnel,
  Task,
} from '../../types'
import { classNames, getDiffInDays } from '../../utils'
import UpdateRecipeScheduleForm from '../recipes/UpdateRecipeScheduleForm'
import CompleteTaskModal from '../modals/completeTaskModal'
import RecipeScheduler from '../feeds/feedEditor/RecipeScheduler'
import { useToasts } from '../toasts/ToastsProvider'
import CandidateCard from './cards/CandidateCard'
import AddTaskModal from '../modals/addTaskModal'
import { ENV_VARS } from '../../env'
import RemoteValue from '../remoteValues/remoteValue'
import ContactCard from './cards/ContactCard'
import { useApi } from '../../store/mainContext'
import CardPopup from './CardPopup'
import Period from '../period'

type Dialog =
  | { type: 'create-task'; relatedId: string }
  | { type: 'complete-task'; task: Task }
  | { type: 'update-task'; task: Task }
  | { type: 'create-schedule'; relatedId: string }
  | { type: 'update-schedule'; schedule: RecipeSchedule }
  | { type: 'delete-schedule'; schedule: RecipeSchedule }

type Card = (Candidate | Contact) & {
  tasks: Task[]
  schedules: RecipeSchedule[]
}

interface Props {
  funnel: Funnel
  cards: Card[]
}

export default function FunnelBoard(props: Props) {
  const { funnel, cards } = props

  const { addToast } = useToasts()
  const { mutate } = useSWRConfig()
  const history = useHistory()
  const api = useApi()

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)
  const [isRemoving, setIsRemoving] = React.useState(false)

  const refresh = () => mutate(['funnel', funnel.name])

  const [cardIdsByColumn, setCardIdsByColumn] = React.useState(() =>
    cards.reduce(
      (acc, card) => ({
        ...acc,
        [card.status || '']: [...(acc[card.status || ''] || []), card._id],
      }),
      {} as Record<string, string[]>,
    ),
  )

  const handleCardStatusChange = async (cardId: string, newStatus: string) => {
    return api
      .patch(`${funnel.collection}/${cardId}`, { status: newStatus })
      .then(refresh)
  }

  const handleDragEnd: OnDragEndResponder = (result) => {
    const cardId = result.draggableId
    const prevStatus = result.source.droppableId
    const newStatus = result.destination?.droppableId

    // don't do anything if the card is dropped outside the chart
    if (!newStatus) return

    const isSameColumn = newStatus === prevStatus
    // only update cards status if we're actually moving between columns
    if (!isSameColumn) handleCardStatusChange(cardId, newStatus)

    setCardIdsByColumn((prev) => {
      const newIndex = result.destination?.index
      if (typeof newIndex !== 'number') return prev
      const originColumn = [...(prev[prevStatus] || [])]
      const destinationColumn = [...(prev[newStatus] || [])]
      const card = originColumn.splice(result.source.index, 1)
      if (isSameColumn) {
        originColumn.splice(newIndex, 0, ...card)
        return { ...prev, [newStatus]: originColumn }
      }
      return {
        ...prev,
        [prevStatus]: originColumn,
        [newStatus]: destinationColumn
          .slice(0, result.destination?.index)
          .concat(card)
          .concat(destinationColumn.slice(result.destination?.index)),
      }
    })
  }

  const handleMarkTaskAsDone = async (task: Task) => {
    if (task.requireDoneNote && !task.doneNote) {
      return setCurrentDialog({ type: 'complete-task', task })
    }
    const { _id, ...rest } = task
    return mutate(
      ['funnel', funnel.name],
      () =>
        api
          .patch('tasks/' + _id, { ...rest, isDone: true })
          // .then(refresh) // not necessary?
          .then(() => setCurrentDialog(null)),
      {
        populateCache: false,

        optimisticData: (_, displayedData) => {
          if (!displayedData) return
          return {
            ...displayedData,
            cards: displayedData.cards.map((c: { tasks: Task[] }) => ({
              ...c,
              tasks: c.tasks.map((t) =>
                t._id === task._id ? { ...t, isDone: true } : t,
              ),
            })),
          }
        },
      },
    )
  }

  const handleConfirmRemove = async (scheduleId: string) => {
    setIsRemoving(true)
    return api
      .delete('recipe-schedules/' + scheduleId)
      .then(refresh)
      .then(() => addToast('Recipe Schedule deleted', { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => {
        setIsRemoving(false)
        setCurrentDialog(null)
      })
  }

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="relative flex grow border-t border-dotted bg-slate-50">
          {funnel.columns.map((column, index, arr) => {
            return (
              <div
                className="flex flex-col border-r border-dotted last:border-r-0"
                style={{ width: `calc(100%/${arr.length})` }}
                key={'col-' + column.id}
              >
                <header className="sticky top-0 flex h-16 items-center justify-center overflow-hidden border-b border-dotted bg-white px-2 text-center text-sm font-semibold uppercase text-slate-700">
                  {column.title}
                </header>

                <Droppable droppableId={column.id}>
                  {(provided, snapshot) => (
                    <ul
                      className={classNames(
                        'grow py-1',
                        snapshot.isDraggingOver && 'bg-slate-100',
                      )}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {(cardIdsByColumn[column.id] || []).map((cardId, i) => {
                        const card = cards.find((c) => c._id === cardId)
                        if (!card) return null
                        return (
                          <Draggable
                            draggableId={card._id}
                            index={i}
                            key={card._id}
                          >
                            {(p, s) => {
                              const isDragging =
                                s.isDragging && !s.isDropAnimating

                              const isRotten =
                                typeof column.rotting === 'number' &&
                                typeof card.lastStatusUpdate === 'number'
                                  ? column.rotting <=
                                    getDiffInDays(
                                      card.lastStatusUpdate,
                                      Date.now(),
                                    )
                                  : false

                              return (
                                <li
                                  className="px-2.5 py-1"
                                  ref={p.innerRef}
                                  {...p.draggableProps}
                                  {...p.dragHandleProps}
                                >
                                  <div
                                    className={classNames(
                                      'cursor-pointer rounded bg-white p-1 [transition:box-shadow_0.1s_ease]',
                                      isDragging
                                        ? '[box-shadow:var(--active-shadow)]'
                                        : 'shadow-[0_5px_20px_0_var(--border-grey),0_0_0_1px_var(--border-grey)] hover:shadow-[0_1px_5px_0_var(--border-grey),0_0_1px_1px_var(--border-grey)]',
                                    )}
                                    onClick={() =>
                                      (ENV_VARS.REACT_APP_USE_NEW_TABS !==
                                        'false'
                                        ? window.open
                                        : history.push)(
                                        `/${funnel.collection}/${card._id}`,
                                      )
                                    }
                                  >
                                    <header
                                      className={classNames(
                                        'px-3 py-3 text-lg font-semibold leading-snug',
                                        isRotten
                                          ? 'text-red-600'
                                          : 'text-slate-700',
                                      )}
                                    >
                                      {card.name}
                                    </header>
                                    <div className="-mt-0.5 flex gap-1.5 pb-2.5 pl-3 pr-2">
                                      <div className="grow">
                                        {funnel.collection === 'candidates' && (
                                          <CandidateCard
                                            candidate={card as Candidate}
                                          />
                                        )}
                                        {funnel.collection === 'contacts' && (
                                          <ContactCard contact={card} />
                                        )}
                                        {isRotten && (
                                          <div className="pt-2 text-sm text-red-600">
                                            Moved{' '}
                                            <Period
                                              date={card.lastStatusUpdate}
                                            />
                                            !
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        className="self-end pb-px"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <CardPopup
                                          onCreateScheduleClick={() => {
                                            setCurrentDialog({
                                              type: 'create-schedule',
                                              relatedId: card._id,
                                            })
                                          }}
                                          onDeleteScheduleClick={(schedule) => {
                                            setCurrentDialog({
                                              type: 'delete-schedule',
                                              schedule,
                                            })
                                          }}
                                          onUpdateScheduleClick={(schedule) => {
                                            setCurrentDialog({
                                              type: 'update-schedule',
                                              schedule,
                                            })
                                          }}
                                          onCreateTaskClick={() => {
                                            setCurrentDialog({
                                              type: 'create-task',
                                              relatedId: card._id,
                                            })
                                          }}
                                          onUpdateTaskClick={(task) => {
                                            setCurrentDialog({
                                              type: 'update-task',
                                              task,
                                            })
                                          }}
                                          onCompleteTaskClick={
                                            handleMarkTaskAsDone
                                          }
                                          relatedCollection={funnel.collection}
                                          schedules={card.schedules}
                                          tasks={card.tasks}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            }}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </div>
            )
          })}
        </div>
      </DragDropContext>

      <AddTaskModal
        relatedCollection={funnel.collection}
        relatedId={
          currentDialog?.type === 'create-task'
            ? currentDialog.relatedId
            : undefined
        }
        onCreated={() => refresh().then(() => setCurrentDialog(null))}
        onEdited={() => refresh().then(() => setCurrentDialog(null))}
        onCancel={() => setCurrentDialog(null)}
        taskToEdit={
          currentDialog?.type === 'update-task' ? currentDialog.task : undefined
        }
        show={
          currentDialog?.type === 'create-task' ||
          currentDialog?.type === 'update-task'
        }
      />

      {currentDialog?.type === 'complete-task' && (
        <CompleteTaskModal
          taskToFullfil={currentDialog.task}
          onMarkAsDone={handleMarkTaskAsDone}
          onCancel={() => setCurrentDialog(null)}
        />
      )}

      <Modal
        centered={false}
        open={currentDialog?.type === 'create-schedule'}
        size="tiny"
        onClose={() => setCurrentDialog(null)}
        onCancel={() => setCurrentDialog(null)}
      >
        <Modal.Header>Schedule a Recipe</Modal.Header>
        <Modal.Content className="!pt-0">
          {currentDialog?.type === 'create-schedule' && (
            <RecipeScheduler
              inlineDateTime
              relatedCollection={funnel.collection}
              relatedId={currentDialog.relatedId}
              onCancel={() => setCurrentDialog(null)}
              onSuccess={() => refresh().then(() => setCurrentDialog(null))}
            />
          )}
        </Modal.Content>
      </Modal>

      <Modal open={currentDialog?.type === 'update-schedule'} size="tiny">
        <Modal.Header>Edit Schedule</Modal.Header>
        <Modal.Content className="!pt-0">
          {currentDialog?.type === 'update-schedule' && (
            <RemoteValue
              collection="recipes"
              id={currentDialog.schedule.recipeId}
              predicate={(recipe: Recipe) => (
                <UpdateRecipeScheduleForm
                  onSuccess={() => refresh().then(() => setCurrentDialog(null))}
                  onCancel={() => setCurrentDialog(null)}
                  schedule={currentDialog.schedule}
                  recipe={recipe}
                />
              )}
            />
          )}
        </Modal.Content>
      </Modal>

      <Confirm
        header="Hey... removing the Recipe Schedule?"
        size="tiny"
        open={currentDialog?.type === 'delete-schedule'}
        closeOnDimmerClick={!isRemoving}
        closeOnEscape={!isRemoving}
        onCancel={() => setCurrentDialog(null)}
        onConfirm={() => {
          if (currentDialog?.type === 'delete-schedule') {
            handleConfirmRemove(currentDialog.schedule._id)
          }
        }}
        content={
          <div style={{ padding: 20 }}>
            <p>
              You are about to remove this Recipe Schedule.{' '}
              <b>This action cannot be undone.</b>
            </p>
            <p>Do you want to continue?</p>
          </div>
        }
        confirmButton={
          <Button
            primary={false}
            color="red"
            loading={isRemoving}
            disabled={isRemoving}
            content="Sure, remove."
          />
        }
      />
    </>
  )
}
