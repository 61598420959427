import { Checkbox, Dropdown, Rating, Input } from 'semantic-ui-react'
import useSWR, { useSWRConfig } from 'swr'
import { Helmet } from 'react-helmet'
import * as React from 'react'
import { z } from 'zod'

import {
  CandidateSearchParams,
  englishLevelSchema,
  entityListSchema,
  candidateSchema,
  senioritySchema,
  profileSchema,
  techSchema,
  Candidate,
} from '../../types'
import { classNames, validateResponse } from '../../utils'
import SaveSearchParamsDialog from '../../components/candidates/SaveSearchParamsDialog'
import FiltersSaveLoadBtns from '../../components/candidates/FiltersSaveLoadBtns'
import SearchResultCard from '../../components/candidates/SearchResultCard'
import OperatorToggle from '../../components/candidates/OperatorToggle'

import FilterSection from '../../components/candidates/FilterSection'
import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'
import CandidatePreview from '../../components/candidates/CandidatePreview'

type CandidateSearchFilters = {
  mainTechs: string[]
  mainTechsOperator: '$all' | '$in'
  profiles: string[]
  profilesOperator: '$all' | '$in'
  yearsInTheIndustry: number
  yearsInTheRole: number
  englishLevel: string[]
  seniority: string[]
  location: string
  text: string[]
  textOperator: '$all' | '$in'
  lists: string[]
  listsOperator: '$all' | '$in' | '$nin'
}

type Dialog = 'save-params'

type State = {
  selectedCandidateId?: string
  filters: CandidateSearchFilters
  dialog?: Dialog
}

type Action =
  | { type: 'saved params loaded'; payload: CandidateSearchParams }
  | { type: 'candidate selected'; payload: Candidate['_id'] }
  | { type: 'filter updated'; payload: Partial<CandidateSearchFilters> }
  | { type: 'filter cleared'; payload: keyof CandidateSearchFilters }
  | { type: 'all filters cleared' }
  | { type: 'dialog closed' }
  | { type: 'dialog open'; payload: Dialog }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'saved params loaded': {
      return {
        ...state,
        filters: paramsToFilters(action.payload),
        dialog: undefined,
      }
    }

    case 'all filters cleared': {
      return { ...state, filters: init().filters }
    }

    case 'filter cleared': {
      const initialState = init()
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload]: initialState.filters[action.payload],
        },
      }
    }

    case 'dialog open':
      return { ...state, dialog: action.payload }

    case 'dialog closed':
      return { ...state, dialog: undefined }

    case 'filter updated':
      return { ...state, filters: { ...state.filters, ...action.payload } }

    case 'candidate selected':
      return { ...state, selectedCandidateId: action.payload }
  }
}

function init(): State {
  return {
    filters: {
      yearsInTheIndustry: 0,
      yearsInTheRole: 0,
      englishLevel: [],
      seniority: [],
      mainTechs: [],
      mainTechsOperator: '$in',
      profiles: [],
      profilesOperator: '$in',
      lists: [],
      listsOperator: '$in',
      location: '',
      text: [],
      textOperator: '$in',
    },
    dialog: undefined,
    selectedCandidateId: undefined,
  }
}

// Converts an Axios query params object into a local Filters state
function paramsToFilters(
  params: CandidateSearchParams,
): CandidateSearchFilters {
  return {
    yearsInTheIndustry: params.yearsInTheIndustry?.$gte || 0,
    yearsInTheRole: params.yearsInTheRole?.$gte || 0,
    englishLevel: params.englishLevel?.$in || [],
    seniority: params.seniority?.$in || [],
    location: params.location || '',
    textOperator: '$all' in (params.text || {}) ? '$all' : '$in',
    text:
      params.text && '$all' in params.text
        ? params.text.$all || []
        : params.text?.$in || [],
    mainTechsOperator: '$all' in (params.mainTechs || {}) ? '$all' : '$in',
    mainTechs:
      params.mainTechs && '$all' in params.mainTechs
        ? params.mainTechs.$all || []
        : params.mainTechs?.$in || [],
    profilesOperator: '$all' in (params.profiles || {}) ? '$all' : '$in',
    profiles:
      params.profiles && '$all' in params.profiles
        ? params.profiles.$all || []
        : params.profiles?.$in || [],
    listsOperator:
      '$all' in (params.lists || {})
        ? '$all'
        : '$nin' in (params.lists || {})
          ? '$nin'
          : '$in',
    lists:
      params.lists && '$all' in params.lists
        ? params.lists.$all || []
        : params.lists && '$nin' in params.lists
          ? params.lists?.$nin || []
          : params.lists?.$in || [],
  }
}

// Converts a local Filters state into an Axios query params object
function filtersToParams(
  filters: CandidateSearchFilters,
): CandidateSearchParams {
  const params: CandidateSearchParams = {}
  if (filters.englishLevel.length) {
    params.englishLevel = { $in: filters.englishLevel }
  }
  if (filters.seniority.length) {
    params.seniority = { $in: filters.seniority }
  }
  if (filters.location.length) {
    params.location = filters.location
  }
  if (filters.text.length && filters.textOperator === '$all') {
    params.text = { $all: filters.text }
  }
  if (filters.text.length && filters.textOperator === '$in') {
    params.text = { $in: filters.text }
  }
  if (filters.yearsInTheIndustry > 0) {
    params.yearsInTheIndustry = { $gte: filters.yearsInTheIndustry }
  }
  if (filters.yearsInTheRole > 0) {
    params.yearsInTheRole = { $gte: filters.yearsInTheRole }
  }
  if (filters.profiles.length && filters.profilesOperator === '$all') {
    params.profiles = { $all: filters.profiles }
  }
  if (filters.profiles.length && filters.profilesOperator === '$in') {
    params.profiles = { $in: filters.profiles }
  }
  if (filters.mainTechs.length && filters.mainTechsOperator === '$all') {
    params.mainTechs = { $all: filters.mainTechs }
  }
  if (filters.mainTechs.length && filters.mainTechsOperator === '$in') {
    params.mainTechs = { $in: filters.mainTechs }
  }
  if (filters.lists.length && filters.listsOperator === '$all') {
    params.lists = { $all: filters.lists }
  }
  if (filters.lists.length && filters.listsOperator === '$in') {
    params.lists = { $in: filters.lists }
  }
  if (filters.lists.length && filters.listsOperator === '$nin') {
    params.lists = { $nin: filters.lists }
  }

  return params
}

export default function CandidatesSearchPage() {
  const showTextFilter = false
  const { mutate: globalMutate } = useSWRConfig()
  const api = useApi()

  const [state, dispatch] = React.useReducer(reducer, null, init)

  const lists = useSWR(['lists', 'candidates'], () =>
    api
      .get('lists', { params: { collection: 'candidates' } })
      .then(validateResponse(z.array(entityListSchema)))
      .then((values) => values.sort((a, b) => a.name.localeCompare(b.name))),
  )

  const techs = useSWR('techs', () =>
    api
      .get('techs')
      .then(validateResponse(z.array(techSchema)))
      .then((values) => values.sort((a, b) => a.text.localeCompare(b.text))),
  )

  const seniority = useSWR('seniority', () =>
    api
      .get('seniority')
      .then(validateResponse(z.array(senioritySchema)))
      .then((values) => values.sort((a, b) => a.value - b.value)),
  )

  const profiles = useSWR('profiles', () =>
    api
      .get('profiles')
      .then(validateResponse(z.array(profileSchema)))
      .then((values) => values.sort((a, b) => a.text.localeCompare(b.text))),
  )

  const englishLevels = useSWR('englishLevels', () =>
    api
      .get('englishLevels')
      .then(validateResponse(z.array(englishLevelSchema)))
      .then((values) => values.sort((a, b) => a.value - b.value)),
  )

  const { filters } = state

  const params = filtersToParams(filters)

  const result = useSWR(
    ['candidates', JSON.stringify(params)],
    () =>
      api
        .get('/candidates/pro', {
          params,
          headers: {
            'astor-limit': 100,
            'astor-skip': 0,
            // 'astor-sortby': "order.sortBy",
            // 'astor-sortby-dir': order.sortByDir,
          },
        })
        .then(validateResponse(z.array(candidateSchema))),
    // handy for keep displaying the filters while executing a new search
    { keepPreviousData: true },
  )

  React.useEffect(() => {
    if (result.data?.length && !state.selectedCandidateId && result.data[0]) {
      dispatch({ type: 'candidate selected', payload: result.data[0]._id })
    }
  }, [result.data, state.selectedCandidateId])

  if (!result.data && result.isLoading) {
    return <PageLoader />
  }

  const selectedCandidate = (result.data || []).find(
    (c) => c._id === state.selectedCandidateId,
  )

  return (
    <React.Fragment>
      <Helmet>
        <title>Candidates Search</title>
      </Helmet>

      <div className="flex h-screen justify-between ">
        {/* FILTERS */}
        <div className="flex">
          <div className=" w-[240px] shrink-0 overflow-y-auto border-r border-dotted border-border-grey bg-bg-grey pb-20 [&::-webkit-scrollbar]:hidden">
            <div className="px-5">
              <div className="flex items-baseline pb-1 pt-5">
                <div className="grow text-sm font-semibold uppercase text-slate-500">
                  Search
                </div>
                <FiltersSaveLoadBtns
                  onSaveClick={() => {
                    dispatch({ type: 'dialog open', payload: 'save-params' })
                  }}
                  onClearAllClick={() => {
                    dispatch({ type: 'all filters cleared' })
                  }}
                  hasAppliedParams={Object.keys(params).length > 0}
                  onSelect={(loadedParams) => {
                    dispatch({
                      type: 'saved params loaded',
                      payload: loadedParams,
                    })
                  }}
                />
              </div>

              {showTextFilter && (
                <>
                  {/* TEXT filter */}
                  <FilterSection
                    title="Text"
                    controls={
                      <OperatorToggle
                        value={filters.textOperator}
                        isHidden={filters.text.length === 0}
                        onChange={(textOperator) => {
                          dispatch({
                            type: 'filter updated',
                            payload: { textOperator },
                          })
                        }}
                      />
                    }
                  >
                    <Dropdown
                      renderLabel={(label) => ({
                        className: classNames(
                          'no-overrides !text-sm !font-normal !text-white',
                          filters.textOperator === '$all'
                            ? '!bg-teal-600'
                            : '!bg-primary',
                        ),
                        content: label.text,
                      })}
                      onChange={(_, p) => {
                        if (
                          Array.isArray(p.value) &&
                          p.value.every((v) => typeof v === 'string')
                        ) {
                          dispatch({
                            type: 'filter updated',
                            payload: { text: p.value as string[] },
                          })
                        }
                      }}
                      value={filters.text}
                      search
                      multiple
                      selection
                      fluid
                      clearable
                      icon={filters.text.length === 0 ? null : undefined}
                      className="no-overrides !rounded [&>.menu.visible]:!hidden"
                      placeholder="Search..."
                      onAddItem={(_, p) => {
                        dispatch({
                          type: 'filter updated',
                          payload: {
                            text: [...filters.text, p.value as string],
                          },
                        })
                      }}
                      allowAdditions
                      options={filters.text.map((text) => ({
                        value: text,
                        text,
                      }))}
                    />
                    <div className="-mb-1  mt-0.5 text-xs text-slate-600">
                      Press{' '}
                      <span className="inline-block rounded border border-slate-400/50 px-1">
                        Enter
                      </span>{' '}
                      to add search terms.
                    </div>
                  </FilterSection>
                </>
              )}

              {/* PROFILES filter */}
              <FilterSection
                title="Profiles"
                controls={
                  <OperatorToggle
                    value={filters.profilesOperator}
                    isHidden={filters.profiles.length === 0}
                    onChange={(profilesOperator) => {
                      dispatch({
                        type: 'filter updated',
                        payload: { profilesOperator },
                      })
                    }}
                  />
                }
              >
                <Dropdown
                  renderLabel={(label) => ({
                    className: classNames(
                      'no-overrides !text-sm !font-normal !text-white',
                      filters.profilesOperator === '$all'
                        ? '!bg-teal-600'
                        : '!bg-primary',
                    ),
                    content: label.text,
                  })}
                  onChange={(_, p) => {
                    if (
                      Array.isArray(p.value) &&
                      p.value.every((v) => typeof v === 'string')
                    ) {
                      dispatch({
                        type: 'filter updated',
                        payload: { profiles: p.value as string[] },
                      })
                    }
                  }}
                  value={filters.profiles}
                  search
                  multiple
                  selection
                  fluid
                  clearable
                  className="no-overrides"
                  placeholder="Select profiles..."
                  options={(profiles.data || []).map((p) => ({
                    value: p._id,
                    text: p.text,
                  }))}
                />
              </FilterSection>

              {/* TECHNOLOGIES filter */}
              <FilterSection
                title="Technologies"
                controls={
                  <OperatorToggle
                    value={filters.mainTechsOperator}
                    isHidden={filters.mainTechs.length === 0}
                    onChange={(mainTechsOperator) => {
                      dispatch({
                        type: 'filter updated',
                        payload: { mainTechsOperator },
                      })
                    }}
                  />
                }
              >
                <Dropdown
                  renderLabel={(label) => ({
                    className: classNames(
                      'no-overrides !text-sm !font-normal !text-white',
                      filters.mainTechsOperator === '$all'
                        ? '!bg-teal-600'
                        : '!bg-primary',
                    ),
                    content: label.text,
                  })}
                  onChange={(_, p) => {
                    if (
                      Array.isArray(p.value) &&
                      p.value.every((v) => typeof v === 'string')
                    ) {
                      dispatch({
                        type: 'filter updated',
                        payload: { mainTechs: p.value as string[] },
                      })
                    }
                  }}
                  value={filters.mainTechs}
                  search
                  multiple
                  selection
                  fluid
                  clearable
                  className="no-overrides"
                  placeholder="Select technologies..."
                  options={(techs.data || []).map((t) => ({
                    value: t._id,
                    text: t.text,
                  }))}
                />
              </FilterSection>

              {/* SENIORITY filter */}
              <FilterSection
                title="Seniority"
                controls={
                  filters.seniority.length > 0 && (
                    <button
                      className="text-sm text-primary underline"
                      onClick={() => {
                        dispatch({
                          type: 'filter cleared',
                          payload: 'seniority',
                        })
                      }}
                      type="button"
                    >
                      Clear
                    </button>
                  )
                }
              >
                <ul className="space-y-2">
                  {(seniority.data || []).map((s) => {
                    return (
                      <li key={s.value}>
                        <label className="cursor-pointer text-slate-600">
                          <Checkbox
                            value={s._id}
                            checked={filters.seniority.includes(s._id)}
                            onChange={(_, p) => {
                              dispatch({
                                type: 'filter updated',
                                payload: {
                                  seniority: p.checked
                                    ? [...filters.seniority, s._id]
                                    : filters.seniority.filter(
                                        (v) => v !== s._id,
                                      ),
                                },
                              })
                            }}
                          />
                          <span className="ml-1 text-base">{s.text}</span>
                        </label>
                      </li>
                    )
                  })}
                </ul>
              </FilterSection>

              {/* YEARS IN THE ROLE filter */}
              <FilterSection
                title="Years in the role"
                controls={
                  state.filters.yearsInTheRole > 0 && (
                    <button
                      className="text-sm text-primary underline"
                      onClick={() => {
                        dispatch({
                          type: 'filter cleared',
                          payload: 'yearsInTheRole',
                        })
                      }}
                      type="button"
                    >
                      Clear
                    </button>
                  )
                }
              >
                <input
                  type="range"
                  className="no-overrides block w-full"
                  min={0}
                  max={10}
                  step={1}
                  value={state.filters.yearsInTheRole}
                  onChange={(e) => {
                    if (typeof e.target.valueAsNumber === 'number') {
                      dispatch({
                        type: 'filter updated',
                        payload: { yearsInTheRole: e.target.valueAsNumber },
                      })
                    }
                  }}
                />
                <div className="mt-1 italic text-slate-500">
                  {state.filters.yearsInTheRole > 0 ? (
                    <>
                      More than{' '}
                      <strong>
                        {state.filters.yearsInTheRole}{' '}
                        {state.filters.yearsInTheRole === 1 ? 'year' : 'years'}
                      </strong>{' '}
                      in the role
                    </>
                  ) : (
                    'Any years in the role'
                  )}
                </div>
              </FilterSection>

              {/* YEARS IN THE INDUSTRY filter */}
              <FilterSection
                title="Years in the industry"
                controls={
                  state.filters.yearsInTheIndustry > 0 && (
                    <button
                      className="text-sm text-primary underline"
                      onClick={() => {
                        dispatch({
                          type: 'filter cleared',
                          payload: 'yearsInTheIndustry',
                        })
                      }}
                      type="button"
                    >
                      Clear
                    </button>
                  )
                }
              >
                <input
                  type="range"
                  className="no-overrides block w-full"
                  min={0}
                  max={10}
                  step={1}
                  value={state.filters.yearsInTheIndustry}
                  onChange={(e) => {
                    if (typeof e.target.valueAsNumber === 'number') {
                      dispatch({
                        type: 'filter updated',
                        payload: { yearsInTheIndustry: e.target.valueAsNumber },
                      })
                    }
                  }}
                />
                <div className="mt-1 italic text-slate-500">
                  {state.filters.yearsInTheIndustry > 0 ? (
                    <>
                      More than{' '}
                      <strong>
                        {state.filters.yearsInTheIndustry}{' '}
                        {state.filters.yearsInTheIndustry === 1
                          ? 'year'
                          : 'years'}
                      </strong>{' '}
                      in any role
                    </>
                  ) : (
                    'Any years in the industry'
                  )}
                </div>
              </FilterSection>

              {/* ENGLISH LEVEL filter */}
              <FilterSection title="Minimum English Level">
                <Rating
                  clearable={false}
                  maxRating={5}
                  rating={
                    filters.englishLevel.length > 0
                      ? 6 - filters.englishLevel.length
                      : 0
                  }
                  icon="star"
                  onRate={(_, p) => {
                    if (typeof p.rating === 'number') {
                      dispatch({
                        type: 'filter updated',
                        payload: {
                          englishLevel: (englishLevels.data || [])
                            .filter((l) => l.value >= Number(p.rating))
                            .map((l) => l._id),
                        },
                      })
                    }
                  }}
                />
                {filters.englishLevel.length > 0 && (
                  <button
                    className="relative -top-px ml-2 text-sm text-primary underline"
                    onClick={() => {
                      dispatch({
                        type: 'filter cleared',
                        payload: 'englishLevel',
                      })
                    }}
                    type="button"
                  >
                    Clear
                  </button>
                )}
              </FilterSection>

              {/* LOCATION filter */}
              <FilterSection title="Location">
                <Input
                  type="text"
                  fluid
                  value={filters.location || ''}
                  onChange={(_, p) =>
                    dispatch({
                      type: 'filter updated',
                      payload: { location: p.value || '' },
                    })
                  }
                  icon={
                    filters.location.length > 0
                      ? {
                          name: 'times',
                          link: true,
                          onClick: () =>
                            dispatch({
                              type: 'filter cleared',
                              payload: 'location',
                            }),
                        }
                      : undefined
                  }
                />
              </FilterSection>

              {/* LISTS filter */}
              <FilterSection
                title="Lists"
                controls={
                  <div className="-mb-1">
                    <Dropdown
                      button
                      basic
                      fluid
                      compact
                      direction="left"
                      trigger={
                        <span
                          className={classNames(
                            'cursor-pointer text-sm font-semibold',
                            state.filters.listsOperator === '$in' &&
                              'text-primary',
                            state.filters.listsOperator === '$all' &&
                              'text-teal-600',
                            state.filters.listsOperator === '$nin' &&
                              'text-amber-600',
                          )}
                        >
                          {state.filters.listsOperator === '$nin' && 'NOT IN'}
                          {state.filters.listsOperator === '$all' && 'AND'}
                          {state.filters.listsOperator === '$in' && 'OR'}
                        </span>
                      }
                      className={classNames(
                        '!flex !items-baseline !px-2 !py-0.5',
                        filters.lists.length === 0
                          ? 'opacity-0'
                          : 'opacity-100',
                      )}
                      options={[
                        {
                          value: '$in',
                          content: (
                            <span className="text-sm font-semibold text-primary">
                              OR
                            </span>
                          ),
                        },
                        {
                          value: '$all',
                          content: (
                            <span className="text-sm font-semibold text-teal-600">
                              AND
                            </span>
                          ),
                        },
                        {
                          value: '$nin',
                          content: (
                            <span className="text-sm font-semibold text-amber-600">
                              NOT IN
                            </span>
                          ),
                        },
                      ]}
                      value={state.filters.listsOperator}
                      onChange={(_, p) => {
                        if (
                          p.value === '$all' ||
                          p.value === '$nin' ||
                          p.value === '$in'
                        ) {
                          dispatch({
                            type: 'filter updated',
                            payload: { listsOperator: p.value },
                          })
                        }
                      }}
                    />
                  </div>
                }
              >
                <Dropdown
                  renderLabel={(label) => ({
                    className: classNames(
                      'no-overrides !text-sm !font-normal !text-white',
                      filters.listsOperator === '$all'
                        ? '!bg-teal-600'
                        : filters.listsOperator === '$in'
                          ? '!bg-primary'
                          : '!bg-amber-600',
                    ),
                    content: label.text,
                  })}
                  onChange={(_, p) => {
                    if (
                      Array.isArray(p.value) &&
                      p.value.every((v) => typeof v === 'string')
                    ) {
                      dispatch({
                        type: 'filter updated',
                        payload: { lists: p.value as string[] },
                      })
                    }
                  }}
                  value={filters.lists}
                  search
                  multiple
                  selection
                  fluid
                  clearable
                  className="no-overrides"
                  placeholder="Select lists..."
                  options={(lists.data || []).map((p) => ({
                    value: p._id,
                    text: p.name,
                  }))}
                />
              </FilterSection>
            </div>
          </div>

          {/* RESULTS LIST */}
          <div
            className={classNames(
              'w-[450px]',
              (result.data || []).length > 0
                ? 'overflow-y-auto [&::-webkit-scrollbar]:hidden '
                : 'flex items-center justify-center',
            )}
          >
            {(result.data || []).length > 0 ? (
              <div className="border-r border-dotted border-border-grey">
                {(result.data || []).map((candidate) => (
                  <div
                    className={classNames(
                      'group cursor-pointer border-b border-dotted border-border-grey px-5 py-4 hover:bg-gray-50',
                      state.selectedCandidateId === candidate._id &&
                        'border-r-primary !bg-primary/5',
                    )}
                    onClick={() => {
                      dispatch({
                        type: 'candidate selected',
                        payload: candidate._id,
                      })
                    }}
                    key={candidate._id}
                  >
                    <SearchResultCard
                      candidate={candidate}
                      mutate={result.mutate}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-full p-4 text-center text-slate-500">
                No candidate matches the current query
              </div>
            )}
          </div>
        </div>

        {selectedCandidate && (
          <CandidatePreview selectedCandidate={selectedCandidate} />
        )}
      </div>

      {state.dialog === 'save-params' && (
        <SaveSearchParamsDialog
          collection="candidates"
          onSuccess={() => {
            globalMutate('candidates-search-params')
            dispatch({ type: 'dialog closed' })
          }}
          onClose={() => dispatch({ type: 'dialog closed' })}
          params={params}
        />
      )}
    </React.Fragment>
  )
}
