import useSWR from 'swr'
import { z } from 'zod'

import {
  recipeScheduleSchema,
  candidateSchema,
  positionSchema,
  companySchema,
  contactSchema,
  funnelSchema,
  taskSchema,
  Candidate,
  Contact,
} from '../types'
import { validateResponse } from '../utils'
import { useApi } from '../store/mainContext'
import usePowers from './usePowers'

export default function useFunnel(funnelName: string) {
  const powers = usePowers()
  const api = useApi()

  return useSWR(['funnel', funnelName], async () => {
    const [funnel] = await api
      .get('funnels', { params: { name: funnelName } })
      .then(validateResponse(z.array(funnelSchema)))

    if (!funnel) return

    const collection = funnel.collection

    const cards = await api
      .get(`${collection}/in`, {
        params: { status: funnel.columns.map((column) => column.id) },
        headers: { 'astor-sortby': 'lastUpdateOn', 'astor-sortby-dir': '1' },
      })
      .then(
        validateResponse(
          collection === 'candidates'
            ? z.array(candidateSchema)
            : z.array(contactSchema),
        ),
      )

    const cardIds = cards.map((c) => c._id)

    const [tasks, schedules, candidatePositions, contactCompanies] =
      await Promise.all([
        api
          .get(`tasks/in`, {
            params: { relatedId: cardIds },
            headers: { 'astor-sortby': 'dueDate', 'astor-sortby-dir': '-1' },
          })
          .then(validateResponse(z.array(taskSchema))),
        powers.hasAll(['run-and-schedule-recipes'])
          ? api
              .get('recipe-schedules/in', {
                headers: {
                  'astor-sortby': 'whenToRun',
                  'astor-sortby-dir': '-1',
                },
                params: { runOnId: cardIds, status: 'pending' },
              })
              .then(validateResponse(z.array(recipeScheduleSchema)))
          : [],
        collection === 'candidates'
          ? api
              .get('/positions/in', {
                params: { _id: cards.map((c) => (c as Candidate).positionId) },
              })
              .then(validateResponse(z.array(positionSchema)))
          : [],
        collection === 'contacts'
          ? api
              .get('/companies/in', {
                params: { _id: cards.map((c) => (c as Contact).companyId) },
              })
              .then(validateResponse(z.array(companySchema)))
          : [],
      ])

    const [positionCompanies, positionHiringManagers] = await Promise.all([
      collection === 'candidates'
        ? api
            .get('/companies/in', {
              params: { _id: candidatePositions.map((p) => p.companyId) },
            })
            .then(validateResponse(z.array(companySchema)))
        : [],
      collection === 'candidates'
        ? api
            .get('/contacts/in', {
              params: { _id: candidatePositions.map((p) => p.hiringManagerId) },
            })
            .then(validateResponse(z.array(contactSchema)))
        : [],
    ])

    return {
      funnel,
      cards: cards.map((card) => {
        const position = candidatePositions.find(
          (c) => c._id === (card as Candidate).positionId,
        )
        return {
          ...card,
          position,
          schedules: schedules.filter((s) => s.runOnId === card._id),
          tasks: tasks.filter((t) => t.relatedId === card._id && !t.isDone),
          hiringManager: positionHiringManagers.find(
            (c) => c._id === position?.hiringManagerId,
          ),
          company:
            collection === 'candidates'
              ? positionCompanies.find((c) => c._id === position?.companyId)
              : contactCompanies.find(
                  (c) => c._id === (card as Contact).companyId,
                ),
        }
      }),
    }
  })
}
