import { KeyedMutator } from 'swr'
import * as React from 'react'
import { Icon } from 'semantic-ui-react'

import CardActionsMenu from './CardActionsMenu'
import { classNames } from '../../utils'
import PositionLabel from './PositionLabel'
import { Candidate } from '../../types'
import RemoteValue from '../remoteValues/remoteValue'
import RemoteStars from '../remoteValues/RemoteStars'

interface Props {
  candidate: Candidate
  mutate: KeyedMutator<Candidate[]>
}

export default function SearchResultCard(props: Props) {
  const { candidate, mutate } = props

  return (
    <>
      <div className="flex items-baseline justify-between gap-5">
        <div className="grow text-nowrap text-xl font-semibold leading-relaxed text-slate-700">
          {candidate.name}
        </div>

        <div>
          <PositionLabel candidate={candidate} />
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <CardActionsMenu candidate={candidate} mutate={mutate} />
        </div>
      </div>

      <div
        className={classNames(
          'mt-4 flex items-start gap-4',
          (candidate.profiles?.length || 0) +
            (candidate.mainTechs?.length || 0) ===
            0 && 'hidden',
        )}
      >
        {candidate.seniority && (
          <div className="min-w-[100px] flex-wrap gap-2">
            <span className="rounded border bg-primary/10 px-1 py-0.5 text-sm text-slate-700">
              <RemoteValue
                collection="seniority"
                id={candidate.seniority}
                predicate={({ text }) => text}
              />
            </span>
          </div>
        )}

        {candidate.profiles && candidate.profiles.length > 0 && (
          <div className="inline-flex flex-wrap gap-2">
            {(candidate.profiles || []).map((id) => (
              <RemoteValue
                collection="profiles"
                id={id}
                key={id}
                predicate={(profile) => (
                  <span className="rounded border bg-primary/20 px-1 py-0.5 text-sm text-slate-700">
                    {profile.text}
                  </span>
                )}
              />
            ))}
          </div>
        )}

        {candidate.mainTechs && candidate.mainTechs.length > 0 && (
          <div className="inline-flex flex-wrap  gap-2 ">
            {(candidate.mainTechs || []).map((id) => (
              <RemoteValue
                collection="techs"
                id={id}
                key={id}
                predicate={(tech) => (
                  <span className="rounded border bg-primary/30 px-1 py-0.5 text-sm text-slate-700">
                    {tech.text}
                  </span>
                )}
              />
            ))}
          </div>
        )}
      </div>

      <div className="mt-4 flex w-full gap-4">
        {candidate.englishLevel && (
          <div className="text-base text-slate-600">
            English level{' '}
            <RemoteStars
              collection="englishLevels"
              maxRating={5}
              value={candidate.englishLevel}
              size="mini"
            />
          </div>
        )}

        {(candidate.location ||
          typeof candidate.yearsInTheIndustry === 'number' ||
          typeof candidate.yearsInTheRole === 'number') && (
          <div className="flex gap-8">
            {candidate.location && (
              <div className="text-nowrap text-base text-slate-600">
                <span className="mr-1 text-rose-600">
                  <Icon name="map pin" fitted className="relative -top-px" />
                </span>{' '}
                From{' '}
                <span className="ml-1 font-semibold">{candidate.location}</span>
              </div>
            )}
          </div>
        )}
      </div>

      {candidate.yearsInTheRole && (
        <div className="mt-4 flex w-full gap-5 text-base text-slate-600">
          <div>
            <span className="font-semibold">
              {candidate.yearsInTheRole}{' '}
              {candidate.yearsInTheRole === 1 ? 'year' : 'years'}
            </span>{' '}
            in the role
          </div>
          <div>
            {' '}
            <span className="font-semibold">
              {candidate.yearsInTheIndustry}{' '}
              {candidate.yearsInTheIndustry === 1 ? 'year' : 'years'}
            </span>{' '}
            in the industry
          </div>
        </div>
      )}
    </>
  )
}
