import { Button, Checkbox, Modal, ModalProps, Select } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { useState } from 'react'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { companySchema, contactSchema, positionSchema } from '../../types'
import { validateResponse } from '../../utils'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

interface Props extends ModalProps {
  defaultPositionId?: string
  defaultShowClosed?: boolean
  candidateId: string
  onSuccess(): Promise<any>
  onClose(): void
  title?: string
}

export default function ChoosePositionModal(props: Props) {
  const {
    defaultPositionId,
    defaultShowClosed,
    candidateId,
    onSuccess,
    title,
    ...other
  } = props

  const { addToast } = useToasts()
  const api = useApi()

  const [selectedPositionId, setSelectedPositionId] =
    useState(defaultPositionId)

  const [showClosed, setShowClosed] = useState(!!defaultShowClosed)

  const options = useSWR(['position-options', showClosed], async () => {
    const positions = await api
      .get('positions', { params: { status: showClosed ? null : 'open' } })
      .then(validateResponse(z.array(positionSchema)))

    const [companies, contacts] = await Promise.all([
      api
        .get('companies/in', {
          params: { _id: positions.map((x) => x.companyId) },
        })
        .then(validateResponse(z.array(companySchema))),
      api
        .get('contacts/in', {
          params: { _id: positions.map((x) => x.hiringManagerId) },
        })
        .then(validateResponse(z.array(contactSchema))),
    ])

    return positions.map((p) => {
      const hiringManager = contacts.find((x) => x._id === p.hiringManagerId)
      const company = companies.find((c) => c._id === p.companyId)
      return {
        value: p._id,
        key: p._id,
        text: `${p.number} - ${company?.name || 'No Company'} - ${p.title} (${hiringManager?.name || 'No HM'})${p.status === 'closed' ? ' (Closed)' : ''}`,
      }
    })
  })

  const [isPending, setIsPending] = useState(false)

  const handlePositionSelected = async () => {
    if (!selectedPositionId) return
    setIsPending(true)
    return api
      .patch('candidates/' + candidateId, { positionId: selectedPositionId })
      .then(() => onSuccess())
      .catch((err: AxiosError) => {
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        })
      })
      .finally(() => setIsPending(false))
  }

  return (
    <Modal
      closeOnDimmerClick={!isPending}
      closeOnEscape={!isPending}
      size="tiny"
      {...other}
    >
      <Modal.Header>{title || 'Choose Position'}</Modal.Header>

      <Modal.Content className="-mt-5">
        <div className="pb-4 text-base leading-snug text-slate-600">
          You can search for the position using{' '}
          <span className="text-primary">it&apos;s title</span>,{' '}
          <span className="text-primary">company name</span> or{' '}
          <span className="text-primary">hiring manager</span>.
        </div>

        <Select
          placeholder={options.isLoading ? 'Loading...' : 'Position...'}
          onChange={(_, p) => {
            if (typeof p.value === 'string') setSelectedPositionId(p.value)
          }}
          options={options.data || []}
          loading={options.isLoading}
          disabled={isPending || options.isLoading}
          value={selectedPositionId}
          search
          fluid
        />

        <Checkbox
          label="Show closed positions too"
          onChange={(_, t) => setShowClosed(!!t.checked)}
          className="mt-6"
          disabled={isPending}
          toggle
          checked={showClosed}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button basic onClick={other.onClose} disabled={isPending}>
          Close
        </Button>
        <Button
          disabled={!selectedPositionId || isPending}
          loading={isPending}
          color="black"
          onClick={() => handlePositionSelected()}
        >
          Yeap, that one
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
