import { Button, Dropdown, Popup } from 'semantic-ui-react'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { SavedSearchParams, savedSearchParamsSchema } from '../../types'
import { classNames, validateResponse } from '../../utils'
import { useApi } from '../../store/mainContext'

interface Props {
  hasAppliedParams?: boolean
  onSelect(search: SavedSearchParams['params']): void
  onClearAllClick(): void
  onSaveClick(): void
}

export default function FiltersSaveLoadBtns(props: Props) {
  const { onSaveClick, onClearAllClick, hasAppliedParams, onSelect } = props

  const api = useApi()

  const { data, isLoading } = useSWR('candidates-search-params', () =>
    api
      .get('search-params', { params: { collection: 'candidates' } })
      .then(validateResponse(z.array(savedSearchParamsSchema))),
  )

  return (
    <div className="flex items-baseline gap-2">
      <button
        className={classNames(
          'text-nowrap text-sm text-primary underline',
          !hasAppliedParams && 'opacity-0',
        )}
        onClick={onClearAllClick}
        disabled={!hasAppliedParams}
        type="button"
      >
        Clear all
      </button>

      <Button.Group basic size="mini" compact>
        <Popup
          content="Save filters"
          position="top center"
          trigger={
            <Button
              icon="save"
              compact
              disabled={!hasAppliedParams}
              onClick={onSaveClick}
            />
          }
          size="tiny"
        />
        <Popup
          content="Load filters"
          position="right center"
          trigger={
            <Dropdown
              className="[&>.dropdown]:!-mr-1 [&>.dropdown]:!ml-1"
              disabled={isLoading || (data || []).length === 0}
              text="Load"
              button
              floating
              options={(data || []).map((s) => ({
                active: false,
                value: s._id,
                text: s.name,
                onClick: (_) => onSelect(s.params),
              }))}
            />
          }
          size="tiny"
        />
      </Button.Group>
    </div>
  )
}
