import { useParams } from 'react-router-dom'
import { Message } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import * as React from 'react'

import FunnelHeader from '../../components/funnels/FunnelHeader'
import FunnelBoard from '../../components/funnels/FunnelBoard'
import PageLoader from '../../components/pageLoader'
import useFunnel from '../../hooks/useFunnel'

export default function FunnelPage() {
  const { funnelName } = useParams<{ funnelName: string }>()

  if (!funnelName) {
    throw new Error('`funnelName` param must be present in the URL')
  }

  const { data, isLoading, error } = useFunnel(funnelName)

  if (isLoading) return <PageLoader />

  if (!data || error) {
    return (
      <Message error>
        Funnel <code>{funnelName}</code> not found.
      </Message>
    )
  }

  return (
    <div className="flex h-screen flex-col">
      <Helmet>
        <title>{data.funnel.title} - Rome</title>
      </Helmet>

      <FunnelHeader funnel={data.funnel} />

      <FunnelBoard funnel={data.funnel} cards={data.cards} />
    </div>
  )
}
