import React from 'react'

import LinkedinViewer from '../linkedinViewer'
import { Candidate } from '../../types'
import Feed from '../feeds/feed'

const CandidatePreview = ({
  selectedCandidate,
}: {
  selectedCandidate: Candidate
}) => {
  const getAge = (dateString: number): number => {
    const today = new Date()
    const birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  const Row = ({
    header,
    content,
  }: {
    header: React.ReactNode
    content: React.ReactNode
  }) => (
    <tr>
      <td className="border border-dotted border-border-grey p-2 font-bold">
        {header}
      </td>
      <td className="w-full border border-dotted border-border-grey border-l-transparent p-2">
        {content}
      </td>
    </tr>
  )

  return (
    <div className="flex grow pl-2 shadow-[inset_0_2px_8px_0px_rgba(0,0,0,0.2)]">
      <div className="min-w-[480px] grow overflow-y-auto border-slate-300  [&::-webkit-scrollbar]:hidden">
        <div className="px-4 py-5">
          <h1>{selectedCandidate.name}</h1>

          <table className="mb-10 w-full">
            <Row header="Email" content={selectedCandidate.email} />
            <Row header="Whatsapp" content={selectedCandidate.whatsapp} />
            <Row header="Location" content={selectedCandidate.location} />
            <Row
              header="Age"
              content={
                selectedCandidate.birthdate &&
                getAge(selectedCandidate.birthdate)
              }
            />
            <Row header="Summary" content={selectedCandidate.summary} />
            <Row header="Referal" content={selectedCandidate.referal} />
          </table>

          <Feed
            relatedId={selectedCandidate._id}
            relatedCollection="candidates"
            showEditor={false}
            showFilter
            showTasks={false}
          />
        </div>
      </div>

      <div className="mt-1 w-[480px] shrink-0 overflow-y-auto bg-white [&::-webkit-scrollbar]:hidden">
        <LinkedinViewer url={selectedCandidate.linkedin} />
      </div>
    </div>
  )
}

export default CandidatePreview
