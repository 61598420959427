import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ApiError } from '../types'
import { ENV_VARS } from '../env'

// Only use this cache for highly immutable resources!
const cacheable = [
  '/v1/talent/techs',
  '/v1/talent/profiles',
  '/v1/talent/seniority',
  '/v1/talent/englishLevels',
  '/v1/talent/badges',
  '/v1/talent/funnels',
  '/v1/talent/timezones',
  '/v2/auth/users',
]

const joinUrls = (baseURL: string, url: string) =>
  baseURL.replace(/\/+$/, '') + '/' + url.replace(/^\/+/, '')

const manageErrors =
  (setError: (newValue: ApiError | undefined) => void) =>
  (error: AxiosError) => {
    if (!error.response) {
      return setError({
        message: error.message || 'Oh oh... something happened!',
        axiosError: error.isAxiosError ? error : undefined,
      })
    }

    if (error.response.status === 500) {
      setError({
        ...error.response.data,
        axiosError: error.isAxiosError ? error : undefined,
      })
      return new Promise(() => {})
    }

    return Promise.reject(error)
  }

const resolveApiBasePaths = (request: AxiosRequestConfig) => {
  const authApiPathsRegExp = new RegExp(['^/?users', '^/?login'].join('|'))

  const talentApiPathsRegExp = new RegExp(
    [
      '^/?submission-status',
      '^/?search-params',
      '^/?email-presets',
      '^/?englishLevels',
      '^/?related-notes',
      '^/?quick-search',
      '^/?submissions',
      '^/?stickynotes',
      '^/?candidates',
      '^/?positions',
      '^/?companies',
      '^/?seniority',
      '^/?timezones',
      '^/?contacts',
      '^/?profiles',
      '^/?funnels',
      '^/?events',
      '^/?badges',
      '^/?lists',
      '^/?techs',
      '^/?tasks',
      '^/?cdn',
      '^/?feeds',
    ].join('|'),
  )

  const contractsApiPathsRegExp = new RegExp(
    [
      '^/?[\\w\\d]+/last-payment-setup',
      '^/?[\\w\\d]+/active-contract',
      '^/?timeoff-transactions',
      '^/?payment-transactions',
      '^/?[\\w\\d]+/contracts',
      '^/?timeoff-requests',
      '^/?billing-transactions',
      '^/?scheduled-emails',
      '^/?billing-payments',
      '^/?billing-invoices',
      '^/?billing-periods',
      '^/?invoice-details',
      '^/?payment-setups',
      '^/?payment-orders',
      '^/?customerSOWs',
      '^/?_changelog',
      '^/?contracts',
      '^/?invoices',
      '^/?suppliers',
      '^/?accounts',
      '^/?accounts',
      '^/?holidays',
      '^/?payrolls',
      '^/?deals',
      '^/?cost-centers',
    ].join('|'),
  )

  const automationApiPathsRegExp = new RegExp(
    ['^/?recipes', '^/?recipes-history', '^/?recipe-schedules'].join('|'),
  )

  if (talentApiPathsRegExp.test(request.url || '')) {
    request.baseURL += '/v1/talent'
  } else if (contractsApiPathsRegExp.test(request.url || '')) {
    request.baseURL += '/v1/contracts'
  } else if (authApiPathsRegExp.test(request.url || '')) {
    request.baseURL += '/v2/auth'
  } else if (automationApiPathsRegExp.test(request.url || '')) {
    request.baseURL += '/v1/automation'
  }
  return request
}

const buildCacheKey = (
  baseURL: string,
  url: string,
  params: Record<string, any>,
) => {
  const fullUrl = joinUrls(baseURL, url)

  return `cache-v2::${fullUrl}::${JSON.stringify(params || {})}`
}

const getFromCache = (request: AxiosRequestConfig) => {
  // debugger;
  const { baseURL, url, method, params } = request

  if (method !== 'get') {
    return request
  }

  const key = buildCacheKey(baseURL || '', url || '', params)

  let dataFromCache: any

  try {
    dataFromCache = JSON.parse(window.localStorage.getItem(key) || '')
  } catch (e) {
    return request
  }

  // todo: add expiration
  if (!dataFromCache) {
    return request
  }

  // console.log(`Getting from cache: ${url}`)

  request.data = dataFromCache

  // Set the request adapter to send the cached response and prevent the request from actually running
  request.adapter = () => {
    return Promise.resolve({
      data: dataFromCache,
      status: 200,
      statusText: 'OK',
      headers: request.headers,
      config: request,
      request,
    })
  }

  return request
}

const setOnCache = (response: AxiosResponse) => {
  if (!response) {
    return
  }

  const { baseURL, method, url, params } = response.config

  const full = joinUrls(baseURL || '', url || '')
  const pathToRequest = new URL(full).pathname

  const isCacheable =
    method === 'get' && cacheable.some((x) => pathToRequest.indexOf(x) === 0)

  if (!isCacheable) {
    return response
  }

  const key = buildCacheKey(baseURL || '', url || '', params)

  window.localStorage.setItem(key, JSON.stringify(response.data))

  return response
}

const setupApi = (
  basePath: string,
  {
    setError,
  }: { setError: React.Dispatch<React.SetStateAction<ApiError | undefined>> },
  useAutoPath = false,
) => {
  const api = axios.create({
    baseURL: ENV_VARS.REACT_APP_API_BASE + (useAutoPath ? '' : basePath),
    responseType: 'json',
    headers: {
      Authorization: `${window.localStorage.getItem('token')}`,
    },
  })

  // On respose, handle errors
  api.interceptors.response.use((res) => res, manageErrors(setError))

  // On request, return the cached version, if any
  api.interceptors.request.use(getFromCache, (err) => Promise.reject(err))

  // On request, derive the corresponding API based on the request path
  if (useAutoPath) {
    api.interceptors.request.use(resolveApiBasePaths, (err) =>
      Promise.reject(err),
    )
  }

  // On response, set or delete the cache
  api.interceptors.response.use(setOnCache, (err) => Promise.reject(err))

  return api
}

export { setupApi }
