import { Placeholder } from 'semantic-ui-react'
import * as React from 'react'
import useSWR from 'swr'

import { positionSchema, companySchema, Candidate } from '../../types'
import { validateResponse } from '../../utils'
import { useApi } from '../../store/mainContext'

interface Props {
  candidate: Candidate
}

export default function PositionLabel(props: Props) {
  const { candidate } = props

  const api = useApi()

  const position = useSWR(
    candidate.positionId ? ['position', candidate.positionId] : null,
    async () => {
      const pos = await api
        .get('/positions/' + candidate.positionId)
        .then(validateResponse(positionSchema))

      const [company] = await Promise.all([
        pos.companyId
          ? api
              .get('/companies/' + pos.companyId)
              .then(validateResponse(companySchema))
          : null,
      ])
      return { ...pos, company }
    },
  )

  if (!candidate.positionId) return null

  return (
    <div className="whitespace-nowrap px-2">
      {position.data ? (
        <span className="text-sm text-slate-600">
          {position.data.number} - {position.data.company?.name || 'No Company'}{' '}
          - {position.data.title}
        </span>
      ) : (
        <div className="inline-block w-20">
          <Placeholder fluid className="-mt-1">
            <Placeholder.Line />
          </Placeholder>
        </div>
      )}
    </div>
  )
}
