import React, { useEffect, useState } from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ChoosePositionModal from './modals/ChoosePositionModal'
import { useTalentApi } from '../store/mainContext'
import ConfirmModal from './modals/confirmModal'
import { ENV_VARS } from '../env'
import CompanyName from './remoteValues/companyName'
import ContactName from './remoteValues/contactName'
import UserName from './remoteValues/userName'
import Period from './period'

const Container = styled.div`
  background-color: var(--primary-light);
  border: dotted 1px var(--border-primary);
  border-radius: 4px;
  padding: 20px;
  margin: 0px 30px 30px 30px;
  position: relative;
  cursor: pointer;
  z-index: 10 !important;

  &:hover {
    border: solid 1px var(--border-primary);
  }
`

const Title = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 15px;
`

const Position = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const Company = styled.div`
  margin-top: 5px;
  font-size: 16px;
`

const Info = styled.div`
  margin-top: 20px;
  font-size: 14px;
`

const P = styled.p`
  color: #666;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  overflow-wrap: none;
`

const Bar = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100px;
  justify-content: space-between;

  top: 15px;
  right: 15px;
`

const Btn = styled.button`
  cursor: pointer;
  border: none;
  background: none;

  &:hover {
    background: none;
    color: var(--primary);
  }
`

const EmptyContaier = styled.div`
  border: dotted 1px var(--border-primary);
  border-radius: 4px;
  padding: 10px;
  margin: 0px 30px 30px 30px;
  position: relative;
  cursor: pointer;
  text-align: center;
  color: var(--primary);
  background-color: var(--primary-light);

  &:hover {
    border: solid 1px #e2dcf2;
  }
`

const PositionLinker = ({ candidate, onSuccess }) => {
  const [expanded, setExpanded] = useState(false)

  const [position, setPosition] = useState()

  const [showChoosePositionModal, setShowChoosePositionModal] = useState(false)

  const [showConfirmUnlink, setShowConfirmUnlink] = useState(false)

  const history = useHistory()
  const api = useTalentApi()

  useEffect(() => {
    if (!candidate || !candidate.positionId) return
    api
      .get(`/positions/${candidate.positionId}`)
      .then(({ data }) => setPosition(data))
  }, [candidate, api])

  const handleUnlink = async () => {
    await api.delete(`candidates/${candidate._id}/positionId`)
    setShowConfirmUnlink(false)
    onSuccess && onSuccess('positionId', null)
  }

  if (!position) {
    return (
      <React.Fragment>
        <EmptyContaier onClick={() => setShowChoosePositionModal(true)}>
          <Icon name="plus" /> Assign Position
        </EmptyContaier>

        <ChoosePositionModal
          candidateId={candidate._id}
          onClose={() => setShowChoosePositionModal(false)}
          onSuccess={onSuccess}
          open={showChoosePositionModal}
        />
      </React.Fragment>
    )
  }

  const icon = expanded ? 'up' : 'down'

  return (
    <React.Fragment>
      <Container onClick={() => setExpanded(!expanded)}>
        <Bar>
          <Dropdown
            icon={null}
            trigger={
              <Btn>
                <Icon name="ellipsis horizontal" />
              </Btn>
            }
            pointing="top right"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text="Unlink"
                icon="broken chain"
                color="red"
                onClick={() => setShowConfirmUnlink(true)}
              />
              <Dropdown.Item
                text="Switch position"
                icon="refresh"
                onClick={() => setShowChoosePositionModal(true)}
              />
              <Dropdown.Divider />

              <Dropdown.Item
                text="Copy contact emails"
                icon="copy outline"
                onClick={() =>
                  alert('Not ready yet. If you want this feature ask Lean.')
                }
              />

              <Dropdown.Divider />
              <Dropdown.Item
                text="View Position"
                icon="share square outline"
                onClick={() =>
                  (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                    ? window.open
                    : history.push)(`/positions/${position._id}`)
                }
              />
            </Dropdown.Menu>
          </Dropdown>
        </Bar>

        <Title>
          <Icon name={`caret ${icon}`} />
          Position
        </Title>
        <Position>
          {position.number} - {position.title}{' '}
          {position.status === 'closed' && '(Closed)'}
        </Position>
        <Company>
          <CompanyName key={position.companyId} id={position.companyId} />
        </Company>

        <br />
        <b>Hiring Manager</b>
        <P>
          <ContactName id={position.hiringManagerId} email />{' '}
        </P>

        {expanded && (
          <React.Fragment>
            {(position.secondContact ||
              position.thirdContact ||
              position.fourthContact) && (
              <Info>
                <b>Secondary Contacts</b>
                {position.secondContact && (
                  <P>
                    <ContactName id={position.secondContact} email />
                  </P>
                )}
                {position.thirdContact && (
                  <P>
                    <ContactName id={position.thirdContact} email />
                  </P>
                )}
                {position.fourthContact && (
                  <P>
                    <ContactName id={position.fourthContact} email />
                  </P>
                )}
              </Info>
            )}

            <Info>
              <b>Job Description</b>
              <P>{position.description || 'Empty.'} </P>
            </Info>

            <Info>
              <b>Salary (Average)</b>
              <P>$ {position.salary || 'Empty.'}</P>
            </Info>

            <Info>
              <b>Openings</b>
              <P>{position.openings || 'Empty.'}</P>
            </Info>

            <Info>
              <P>
                <b>Open</b> <Period date={position.openOn} /> <b>by</b>{' '}
                <UserName id={position.openBy} />
              </P>
            </Info>
          </React.Fragment>
        )}
      </Container>

      <ChoosePositionModal
        candidateId={candidate._id}
        onClose={() => setShowChoosePositionModal(false)}
        onSuccess={onSuccess}
        show={showChoosePositionModal}
      />

      <ConfirmModal
        show={showConfirmUnlink}
        header="Unlink Position"
        question="You are about to unlink this position:"
        highlight={`${position.number} - ${position.title}`}
        onCancel={() => setShowConfirmUnlink(false)}
        onConfirm={handleUnlink}
      />
    </React.Fragment>
  )
}

export default PositionLinker
