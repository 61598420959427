import * as React from 'react'
import {
  Button,
  Confirm,
  ConfirmProps,
  Modal,
  SemanticCOLORS,
} from 'semantic-ui-react'

interface Props {
  show: boolean
  title?: ConfirmProps['header']
  question: string
  highlight?: string
  onConfirm(): Promise<any>
  onCancel(): void
  confirmButtonColor?: SemanticCOLORS
}

const ConfirmModal = ({
  show,
  title,
  question,
  highlight,
  onConfirm,
  onCancel,
  confirmButtonColor,
}: Props) => {
  const [isPending, setIsPending] = React.useState(false)
  return (
    <Confirm
      open={show}
      header={title}
      content={
        <Modal.Content>
          <p>{question}</p>

          {highlight && (
            <p className="highlight" style={{ fontSize: 17 }}>
              <b>{highlight}</b>
            </p>
          )}

          <p>Are you sure?</p>
        </Modal.Content>
      }
      onCancel={onCancel}
      closeOnDimmerClick={!isPending}
      closeOnEscape={!isPending}
      size="tiny"
      onConfirm={() => {
        setIsPending(true)
        onConfirm().then(() => setIsPending(false))
      }}
      confirmButton={
        <Button
          loading={isPending}
          disabled={isPending}
          negative
          color={confirmButtonColor ?? 'red'}
        >
          Yeap... Let&apos;s do it.
        </Button>
      }
      cancelButton={
        <Button disabled={isPending} basic>
          Ugh... no, no yet.
        </Button>
      }
    />
  )
}

export default ConfirmModal
