import React, { useState, useEffect } from 'react'
import { Button, Confirm, Icon } from 'semantic-ui-react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import Editor from '@monaco-editor/react'
import { z } from 'zod'

import { Funnel, funnelSchema } from '../../types'
import { validateResponse } from '../../utils'
import ConfirmModal from '../../components/modals/confirmModal'
import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'

const Header = styled.div`
  display: flex;
  padding: 15px;
  align-content: center;
  justify-content: space-between;
  background-color: #fff;
`

const CodeEditor = styled(Editor)`
  border: solid 1px var(--border-grey);
  border-radius: 4px;
  &:focus {
    border-color: red !important;
  }
`

export default function FunnelEditPage() {
  const { funnelName } = useParams<{ funnelName: string }>()

  if (!funnelName) {
    throw new Error('`funnelName` param must be present in the URL')
  }

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [funnel, setFunnel] = useState<Funnel>()

  const [value, setValue] = useState('')

  const [showConfirm, setShowConfirm] = useState(false)

  const [creating, setCreating] = useState(false)

  const api = useApi()

  useEffect(() => {
    setIsLoading(true)
    api
      .get('/funnels', { params: { name: funnelName } })
      .then(validateResponse(z.array(funnelSchema)))
      .then((data) => {
        const funnelConfig = data[0]
        if (!funnelConfig) return

        setFunnel(funnelConfig)

        const newVal: { [k: string]: any } = { ...funnelConfig }
        delete newVal._id
        delete newVal.lastUpdateBy
        delete newVal.lastUpdateOn
        delete newVal.name
        delete newVal.cardType
        setValue(JSON.stringify(newVal, null, 2))
      })
      .catch((e) => console.warn(e))
      .finally(() => setIsLoading(false))
  }, [api, funnelName])

  const handleCreate = async () => {
    setCreating(true)
    await api.post('/funnels', {
      order: 99,
      name: funnelName,
      title: funnelName,
      sub: 'Say something...',
      collection: 'candidates',
      cardType: 'candidates',
      columns: [
        {
          id: `${funnelName}.dealIn`,
          title: 'Deal In',
          rotting: 5,
        },
      ],
    })

    resetStorage()

    document.location.reload()
  }

  const resetStorage = () => {
    Object.keys(window.localStorage)
      .filter((key) => key.startsWith('cache-'))
      .forEach((key) => window.localStorage.removeItem(key))
  }

  const handleSave = async () => {
    if (!funnel) return
    let newValue

    try {
      newValue = JSON.parse(value)
      resetStorage()
    } catch (e) {
      alert('Error parsing the JSON!')
      return
    }
    delete newValue._id
    await api.patch(`funnels/${funnel._id}`, newValue)
    history.goBack()
  }

  if (isLoading) return <PageLoader />

  if (!funnel) {
    return (
      <div style={{ padding: `25px 25px 0px 25px` }}>
        <p>
          Funnel <b>{funnelName}</b> does not exits.{' '}
        </p>

        <p>
          Click{' '}
          <Button
            loading={creating}
            size="tiny"
            color="green"
            onClick={handleCreate}
          >
            Here
          </Button>{' '}
          to create it.
        </p>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Header>
        <Button basic onClick={() => history.goBack()}>
          <Icon name="angle left" /> Cancel
        </Button>

        <Button color="black" onClick={() => setShowConfirm(true)}>
          <Icon name="save" /> Save
        </Button>
      </Header>

      <CodeEditor
        defaultLanguage="json"
        height="calc(100vh - 66px)"
        theme="vs-dark"
        options={{
          lineNumbers: 'off',
          padding: { top: 10 },
          wordWrap: 'on',
          fontSize: 16,
        }}
        value={value}
        onMount={(editor) => {
          setTimeout(() => {
            editor.getAction('editor.action.formatDocument').run()
          }, 10)
        }}
        onChange={(code) => {
          if (typeof code === 'string') setValue(code)
        }}
      />

      <ConfirmModal
        show={showConfirm}
        question={`You are about to modify ${funnel.title} funnel.`}
        onCancel={() => {
          setShowConfirm(false)
        }}
        onConfirm={handleSave}
      />

      <Confirm show={showConfirm} />
    </React.Fragment>
  )
}
