import * as React from 'react'

import { Company, Contact } from '../../../types'

interface Props {
  contact: Partial<Contact & { company: Company }>
}

export default function ContactCard(props: Props) {
  const { contact } = props

  return (
    <React.Fragment>
      {contact.position && (
        <div className="text-base leading-tight text-slate-500">
          {contact.position}
        </div>
      )}

      {contact.company && (
        <div className="mt-1 text-base font-semibold leading-tight text-slate-500">
          {contact.company.name}
        </div>
      )}
    </React.Fragment>
  )
}
