import { Checkbox } from 'semantic-ui-react'
import * as React from 'react'

import { classNames } from '../../utils'

interface Props {
  onChange(newValue: '$all' | '$in'): void
  isHidden?: boolean
  value: '$all' | '$in'
}

export default function OperatorToggle(props: Props) {
  const { value, onChange, isHidden } = props

  return (
    <div
      className={classNames(
        '-mr-2 flex items-center text-sm font-semibold text-slate-500',
        isHidden ? 'pointer-events-none opacity-0' : 'opacity-100',
      )}
    >
      <span
        className="cursor-pointer px-2 py-0.5 text-primary"
        onClick={() => onChange('$in')}
      >
        OR
      </span>{' '}
      <Checkbox
        className="[&>label::before]:!bg-slate-600"
        slider
        onChange={(_, p) => onChange(p.checked ? '$all' : '$in')}
        checked={value === '$all'}
      />{' '}
      <span
        className="cursor-pointer px-2 py-0.5 text-teal-600"
        onClick={() => onChange('$all')}
      >
        AND
      </span>
    </div>
  )
}
