import { Link, LinkProps } from 'react-router-dom'
import { Breadcrumb } from 'semantic-ui-react'
import React from 'react'

interface Props {
  title?: React.ReactNode
  breadcrumb?: { text: string; sub?: string; link?: LinkProps['to'] }[]
  actions?: React.ReactNode
  search?: React.ReactNode
  sub?: React.ReactNode
}

const PageHeader = ({ breadcrumb, actions, search, title, sub }: Props) => {
  return (
    <React.Fragment>
      {breadcrumb && (
        <div className="mx-14 my-3">
          <Breadcrumb>
            {(breadcrumb || []).map((x, index) => {
              if (index === breadcrumb.length - 1) {
                return (
                  <Breadcrumb.Section active key={x.text}>
                    {x.text}{' '}
                    {x.sub && (
                      <span className="font-normal text-slate-500">
                        ({x.sub})
                      </span>
                    )}
                  </Breadcrumb.Section>
                )
              }

              return (
                <React.Fragment key={x.text}>
                  <Breadcrumb.Section>
                    {x.link ? <Link to={x.link}>{x.text}</Link> : x.text}
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon="right chevron" />
                </React.Fragment>
              )
            })}
          </Breadcrumb>
        </div>
      )}

      {title && (
        <div className="group mx-14 my-6 flex justify-between gap-3">
          <div className="grow">
            {typeof title === 'string' ? (
              <h1 className="mb-1">{title}</h1>
            ) : (
              <div>{title}</div>
            )}
            {typeof sub === 'string' ? (
              <p className="max-w-3xl leading-normal text-slate-500">{sub}</p>
            ) : (
              <div>{sub}</div>
            )}
          </div>

          <div className="flex justify-between gap-3">
            <div>{search}</div>
            <div>{actions}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default PageHeader
