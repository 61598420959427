import React, { ReactElement, ReactNode } from 'react'
import { Placeholder } from 'semantic-ui-react'
import useSWR from 'swr'

import { Collection } from '../../types'
import { useApi } from '../../store/mainContext'

interface Props {
  forceLoading?: boolean
  showLoading?: boolean
  collection?: Collection
  predicate?: (input: any) => ReactNode
  placeholder?: ReactElement
  id?: string
}

const RemoteValue = (props: Props) => {
  const { collection, id, showLoading, predicate, placeholder } = props

  const api = useApi()

  const { data, isLoading } = useSWR(
    [collection, id],
    () => {
      return collection && id
        ? api.get(`${collection}/${id}`).then((res) => res.data)
        : null
    },
    { shouldRetryOnError: false },
  )

  const renderer = predicate || ((x) => x.text)

  if (props.forceLoading || (isLoading && showLoading)) {
    return (
      placeholder || (
        <Placeholder className="-mt-1.5">
          <Placeholder.Line length="long" className="m-0" />
        </Placeholder>
      )
    )
  }

  if (!data) return null

  return <React.Fragment>{renderer(data)}</React.Fragment>
}

export default RemoteValue
