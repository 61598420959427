import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Form, Input, Modal, ModalProps } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import * as React from 'react'

import { Collection, SavedSearchParams } from '../../types'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

type FormValues = {
  name: string
}

interface Props extends ModalProps {
  collection: Collection
  params: SavedSearchParams['params']
  onSuccess(): void
}

export default function SaveSearchParamsDialog(props: Props) {
  const { collection, params, onSuccess, ...other } = props

  const { addToast } = useToasts()
  const api = useApi()

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { name: '' },
  })

  const [isSaving, setIsSaving] = React.useState(false)

  const handleSave: SubmitHandler<FormValues> = async (data) => {
    setIsSaving(true)
    return api
      .post('search-params', { name: data.name, collection, params })
      .then(() => {
        addToast('Search parameters saved', { variant: 'success' })
      })
      .then(onSuccess)
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsSaving(false))
  }

  return (
    <Modal size="tiny" open {...other}>
      <Modal.Header>Save search parameters</Modal.Header>

      <Modal.Content className="-mt-4">
        <Form
          id="save-search-params"
          onSubmit={handleSubmit(handleSave)}
          spellCheck="false"
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => {
              return (
                <Form.Field>
                  <label className="text-sm font-semibold leading-none">
                    Provide a name
                  </label>
                  <Input
                    {...field}
                    onChange={(_, { value }) => field.onChange(value)}
                    fluid
                    error={!!fieldState.error}
                    disabled={isSaving}
                    input={
                      <input data-1p-ignore autoFocus {...props.inputProps} />
                    }
                  />
                  <div className="text-base leading-normal text-red-600">
                    {fieldState.error?.message}
                  </div>
                </Form.Field>
              )
            }}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions
        className="-mt-2"
        actions={[
          {
            key: 'close',
            content: 'Close',
            onClick: other.onClose,
            basic: true,
          },
          {
            key: 'save',
            content: 'Save',
            disabled: isSaving,
            primary: true,
            type: 'submit',
            form: 'save-search-params',
          },
        ]}
      />
    </Modal>
  )
}
