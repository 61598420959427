import useSWR from 'swr'
import { z } from 'zod'

import { Collection, funnelSchema } from '../types'
import { validateResponse } from '../utils'
import { useTalentApi } from '../store/mainContext'

export default function useFunnels(collection: Collection) {
  const api = useTalentApi()

  return useSWR(['funnels', collection], () =>
    api
      .get('funnels', {
        params: { collection },
        headers: {
          'astor-sortby': 'order',
          'astor-sortby-dir': '1',
        },
      })
      .then(validateResponse(z.array(funnelSchema))),
  )
}
