import * as React from 'react'

interface Props {
  title: string
  controls?: React.ReactNode
  children: React.ReactNode
}

export default function FilterSection(props: Props) {
  const { title, controls, children } = props

  return (
    <section className="py-3">
      <header className="flex items-baseline justify-between gap-2 pb-2 pt-1">
        <span className="text-sm font-semibold uppercase text-slate-500">
          {title}
        </span>
        {controls}
      </header>
      {children}
    </section>
  )
}
